@import '../../assets/variables/colors.scss';

.contact {
    display: flex;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 44px;

    &__image {
        width: 562px;
        height: 749px;
        object-fit: cover;
        border-radius: 16px;
    }

    &__form {
        display: flex;
        align-items: flex-start;
        background-color: $white;
        padding: 80px 96px;
        border-radius: 2px;
        width: 100%;

        &__left {
            margin-right: 54px;
            width: 100%;

            &-title {
                color: $purple;
                font-weight: 500;
                font-size: 50px;
                margin-bottom: 25px;
            }

            .field {
                margin-bottom: 25px;

                .input {
                    width: 100%;
                    height: 40px;
                    background-color: $body;
                    border: 1px solid $border;
                    text-indent: 12px;
                    margin-top: 10px;
                    border-radius: 3px;
                    outline: none;

                    &.area {
                        padding: 9px 0px;
                        height: 140px;
                        resize: none;
                    }

                    &.error {
                        border: 1px solid $warning;
                    }
                }

                .label {
                    font-size: 14px;
                    color: $black;
                    font-weight: 400;
                }
            }

            .send {
                border-radius: 9px;
                background-color: $purple;
                height: 47px;
                width: 100%;
                color: $white;
                transition: all .5s;

                &:hover {
                    background-color: $white;
                    border: 1px solid $purple;
                    color: $purple;
                }
            }
        }

        &__right {
            margin-top: 15px;

            &-about {
                font-size: 14px;
                font-weight: 400;
                color: $black;
                width: 273px;
            }

            &__location {
                &-title {
                    font-size: 18px;
                    font-weight: 500;
                    display: block;
                    margin-bottom: 8px;
                    margin-top: 15px;

                }

                &-location {
                    display: flex;
                    align-items: center;

                    &-circle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        background-color: $background;
                        margin-right: 5px;
                    }

                    &-title {
                        font-size: 14px;
                        font-weight: 400;
                        color: $black;
                    }
                }
            }
        }
    }
}

.main {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .footers {
        margin-top: auto;
    }
}