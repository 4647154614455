.containerJob {
  gap: 0px;
  padding: 0px;
  .addJob {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 30px 0px;
  }

  .fields {
    margin-top: 0px;
    gap: 10px;
    margin-right: 0px;
    margin-bottom: 25px;

    .label {
      margin-bottom: 0px;
    }
    .salary_label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .fields_flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 27px;
    flex-wrap: wrap;
    row-gap: 15px;

    .many {
      margin-left: 0px;
    }
  }
  .next {
    height: 50px;
    max-width: 180px;
  }
  .imagePickerContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
  }
  .button_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
  }
  .btn_container {
    width: 100%;
    margin-top: 30px;
    .one-btn {
      max-width: 100%;
      width: 100%;
    }
  }
}
