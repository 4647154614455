@import '../../../../assets/variables/colors.scss';

/* width */
.list::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}

/* Track */
.list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.settings {
    &__header {
        display: flex;
        align-items: center;

        &__ul {
            margin-top: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            height: 44px;

            &__li {
                white-space: nowrap;

                &-title {
                    color: $black;
                    font-weight: 400;
                    font-size: 16px;
                    min-width: 126px;
                    margin-right: 13px;
                    position: relative;

                    &.active {
                        .line {
                            width: 100%;
                        }
                    }

                    &:hover {
                        cursor: pointer;

                        .line {
                            width: 100%;
                        }
                    }

                    .line {
                        height: 2px;
                        background-color: $purple;
                        display: block;
                        border-radius: 1px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform: translateY(10px);
                        transition: all .5s;
                        width: 0;
                    }
                }
            }
        }
    }
}