@import '../../assets/variables/colors.scss';

/* width */
.apps__filter__messages::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.apps__filter__messages::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.apps__filter__messages::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.apps {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .back_buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $purple;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        margin: 0 30px 20px;
    }

    .middle {
        display: flex;
        width: 100%;
        margin: auto;
        margin-top: 0;

    }

    .footers {
        margin-top: auto;
    }

    .dropdown-app {
        width: 100%;
        border: 1px solid $border;

        fieldset {
            border: none;
        }
    }

    &__filter {
        width: 422px;
        border-radius: 1px;
        margin-right: 20px;

        &__messages {
            height: 420px;
            overflow-y: auto;
            padding-right: 10px;

            &-message {
                margin-bottom: 6px;
            }
        }

        .filter-title {
            color: $black;
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 13px;
            display: block;
            text-align: center;
        }

        &__incoming {
            padding: 40px 23px;
            background-color: $white;
            border-radius: 2px;
            margin-bottom: 20px;
            &.mess {
                padding: 20px 31px;
            }

            &-desc {
                font-size: 14px;
                font-weight: 400;
                color: $black;
                text-align: center;
                line-height: 20px;
                width: 270px;
                margin: auto;
                margin-bottom: 17px;

                .job-opening {
                    color: $purple;
                    font-weight: 500;
                }
            }

            &__swiper {
                margin-top: 20px;
                width: 100%;
                background-color: $light-purple;
                border-radius: 32px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px;

                &-btn {
                    text-align: center;
                    display: block;
                    width: 48%;
                    border-radius: 45px;
                    transition: all .5s;
                    cursor: pointer;
                    color: $soft-purple;
                    padding: 5px 6px;

                    &:hover {
                        color: $white;
                        background-color: $purple;
                    }

                    &.active {
                        color: $white;
                        background-color: $purple;
                    }
                }
            }
        }

        &-title {
            color: $black;
            font-size: 24px;
            font-weight: 600;
            display: block;
            text-align: center;
        }
    }

    &__matches {
        background-color: $white;
        border-radius: 2px;
        width: 100%;
        padding: 25px 20px;

        &-title {
            font-size: 24px;
            color: $black;
            font-weight: 600;
            margin-bottom: 35px;
            display: block;
            text-align: center;

            &.incoming {
                margin-top: 30px;
            }
        }

        &__card {
            margin-bottom: 15px;
        }
    }
}