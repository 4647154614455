@import '../../assets/variables/colors.scss';

.ourBenefits {
  gap: 0px;
  padding: 0px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;

  .sharedContainer {
    max-width: 100%;
    .drag-and-drop_image {
      max-width: 100%;
    }
  }

  .addBenefits {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 0px;
    margin-bottom: 20px;
  }

  .fields {
    margin-top: 0px;
    gap: 10px;
    margin-right: 0px;
    margin-bottom: 0px;

    .label {
      margin-bottom: 0px;
    }
    .salary_label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .fields_flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 27px;

    .many {
      margin-left: 0px;
    }
  }
  .next {
    height: 50px;
    max-width: 180px;
    background-color: $purple;
    color: $white;
    width: 170px;
  }

  .imagePickerContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
  }
  .button_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
