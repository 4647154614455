@import '../../assets/variables/colors.scss';

.result-header {
  padding: 20px 50px;
  max-width: 2050px;

  .back_button {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $purple;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  .match-settings {
    margin-top: 25px;
    background-color: $white;
    padding: 25px;
    border-radius: 6px;
    font-family: Rubik;
    font-style: normal;
    line-height: normal;

    .top {
      display: flex;
      justify-content: space-between;

      .match-settings-text {
        color: $black;

        .title {
          font-size: 24px;
          font-weight: 600;
        }

        .text {
          opacity: 0.7;
          font-size: 18px;
          font-weight: 500;
          margin-top: 10px;
          display: inline-block;
          word-wrap: break-word;
          max-width: 700px;
        }
      }

      .edit-mach {
        padding: 10px 22px;
        color: $white;
        background-color: $purple;
        font-size: 16px;
        font-weight: 500;
        border-radius: 9px;
        height: fit-content;
      }
    }

    .bottom {
      display: flex;
      gap: 18px;
      margin-top: 25px;
      flex-wrap: wrap;

      .settings-item {
        padding: 10px;
        background-color: $light-purple;
        color: $purple;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        border-radius: 28px;
      }
    }
  }
}
