@import '../../assets/variables/colors.scss';

.default {
    position: relative;
    border-radius: 50%;
    background-color: $purple;
    width: 44px;
    height: 44px;
    display: block;

    .letter {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font-weight: 500;
        font-size: 25px;
    }
}