@mixin laptop {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 991px) {
        @content;
    }
}

@mixin tabletS {
    @media screen and (max-width: 809px) {
        @content;
    }
}

@mixin tabletXS {
    @media screen and (max-width: 757px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 575px) {
        @content;
    }
}

@mixin mobileXS {
    @media screen and (max-width: 460px) {
        @content;
    }
}