@import '../../assets/variables/colors.scss';

.invitaition {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 20px;
  &_title {
    font-family: Rubik;
    font-size: 40px;
    font-weight: 700;
  }
  &_selector {
    display: flex;
    gap: 10px;
    .violetButton {
      color: #ffffff;
      width: 98px;
      height: 36px;
      background: linear-gradient(92.25deg, #9b5fda -2.57%, #562c82 113.38%);
      font-family: Rubik;
      font-weight: 700;
      border-radius: 30px;
      border: none;
      cursor: pointer;
    }
    .violetButtonDisabled {
      color: black;
      width: 98px;
      height: 36px;
      background: transparent;
      font-family: Rubik;
      font-weight: 700;
      border-radius: 30px;
      border: none;
      cursor: pointer;
    }
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 20px;
    width: 100%;
    max-width: 1520px;
    &_card {
      background: linear-gradient(
        94.35deg,
        rgba(255, 255, 255, 0.8) -5.21%,
        rgba(255, 255, 255, 0.3) 114.12%
      );
      box-shadow: rgb(0 0 0 / 8%) 0px 0px 10px 0px;
      border-radius: 20px;
      padding: 30px 30px 40px;
      width: 700px;
      height: 201px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &_item {
        display: flex;
        justify-content: space-between;
        &_email {
          margin: 0px;
          font-family: Rubik, sans-serif;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5;
        }
        &_status_accepted {
          background: rgba(155, 255, 155, 0.2);
          color: rgb(138, 233, 138);
          border-radius: 30px;
          display: flex;
          justify-content: center;
          height: 36px;
          align-items: center;
          padding: 16px;
        }
        &_status_notAccepted {
          background: rgba(240, 180, 89, 0.2);
          color: rgb(240, 180, 89);
          border-radius: 30px;
          display: flex;
          justify-content: center;
          height: 36px;
          align-items: center;
          padding: 16px;
        }
        &_actions {
          display: flex;
          justify-content: space-between;
          &_invited {
            display: flex;
            flex-direction: column;
            p {
              font-family: Rubik;
              font-weight: 700;
              font-size: 1rem;
            }
            span {
              margin: 0px;
              font-family: Rubik, sans-serif;
              font-weight: 400;
              font-size: 0.75rem;
              line-height: 1.5;
            }
          }
          &_btns {
            display: flex;
            align-items: center;
            gap: 5px;
            &_resend {
              background-color: transparent;
              color: rgb(86, 44, 130);
              font-family: Rubik;
              box-shadow: none;
              font-weight: 700;
              border: none;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
            &_delete {
              width: 100%;
              background-color: rgb(255, 104, 104);
              color: white;
              border-radius: 30px;
              padding: 10px 15px;
              border: none;
              box-shadow: rgb(86 44 130 / 24%) 0px 8px 16px 0px;
              cursor: pointer;
              &:hover {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    .divider {
      margin: 8px 0px;
      flex-shrink: 0;
      border-width: 0px 0px thin;
      border-style: solid;
      border-color: rgba(145, 158, 171, 0.24);
    }
  }

  .back_buttons {
    position: absolute;
    color: $purple;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    top: 0;
    left: 10%;
  }
}
