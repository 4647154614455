@import '../../../../assets/variables/colors.scss';

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: $purple !important;
}

/* width */
.fields::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.fields::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.fields::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.activation {
    top: 300px;
}

.preferences {
    .activation {
        all: initial;
    }

    .error {
        border: 1px solid $warning !important;
    }

    .ind {
        margin-bottom: 20px;
    }

    .form-body {
        height: 580px;
        overflow-y: auto !important;
        padding-right: 20px;
        margin-top: 25px;
    }

    .industry-selected {
        height: 26px;
        background-color: $body;
        color: $gray;
        font-size: 12px;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
        padding: 0 5px;
        margin-top: 10px;

        .remove {
            cursor: pointer;
            margin-left: 8px;
        }
    }

    .top {
        display: flex;
        flex-direction: column;
    }

    .next {
        width: 100%;
        border-radius: 9px;
        background-color: $purple;
        color: $white;
        border: 1px solid $purple;

        &:hover {
            color: $purple;
        }
    }

    .label {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        display: block;
        margin-bottom: 8px;

        &.gender {
            font-size: 16px;
            font-weight: 600;
            display: block;
            margin-top: 25px;
        }
    }

    .fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 0 !important;

        &.loc {
            margin-bottom: 20px;
        }

        &:first-child {
            max-width: 100% !important;
        }

        &.willing {
            width: 100% !important;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .checkboxes {
        .bold-title {
            font-weight: 600;
            font-size: 16px;
            color: $black;
        }

        .contract {
            width: 100%;

            .MuiFormGroup-row {
                justify-content: space-between;
            }
        }

        .input {

            width: 100%;
        }
    }



    .input,
    .dropdown {
        border-radius: 3px;
        border: 1px solid $border;
        background: $background;
        height: 40px;
        outline: none;
        text-indent: 12px;
        margin-bottom:0;

        fieldset {
            outline: none;
            border: 0;
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        .button {
            width: 187px;
            height: 47px;
            border-radius: 9px;

            &.back {
                background-color: $storm-white;
                color: $purple;
            }

            &.next {
                background-color: $purple;
                color: $white;

                &.invalid {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }
}

.MuiOutlinedInput-root {
    height: 40px;
}

.MuiInputBase-root {
    background-color: $body;
    border: 1px solid $border;
}

.MuiOutlinedInput-notchedOutline {
    border: none;
}

.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
    transform: translateX(-25px);
}