@import '../../../assets/variables/colors.scss';

.tooltip {
  padding: 10px 35px 10px 10px;
  border-radius: 9px 9px 9px 0px;
  box-shadow: 0px 1px 13.5px 0px rgba(123, 138, 156, 0.4);
  background-color: $white;
  visibility: hidden;
  top: 10px;
  left: 0;

  &.show {
    visibility: visible;
  }

  &__message {
    color: $black;
    font-weight: 400;
    font-size: 12px;
    white-space: nowrap;
  }
}
