@import '../../../assets/variables/colors.scss';

.desc {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        width: 427px;
    }

    .right {
        display: flex;

        .action_buttons {
            padding: 5px;
            display: block;
            cursor: pointer;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            color: $black;
            font-size: 18px;
            font-weight: 600;
            display: block;
            margin-bottom: 8px;

            &.purple {
                color: $purple;
            }
        }

        &-years {
            opacity: 0.8;
            color: $black;
            font-size: 14px;
        }
    }

    &__about {
        font-size: 12px;
        font-weight: 400;
        opacity: 0.8;
        color: $black;
        width: 350px;
    }

    &__more {
        display: flex;
        align-items: center;
        margin-top: 12px;
        margin-bottom: 20px;

        &__icons {
            margin-right: 24px;
            display: flex;
            align-items: center;
            width: 176px;

            &-icon {
                padding: 6px;
                background-color: $background;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-right: 6px;
            }

            &-title {
                color: $black;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}