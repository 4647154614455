@import '../../../../assets/variables/colors.scss';

/* width */
.wexperience-descripts::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.wexperience-descripts::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.wexperience-descripts::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.wexperience {
    overflow-y: auto;
    max-height: 582px;
    padding: 0 10px;
    .disable {
        opacity: 0.5;
        pointer-events: none;
    }

    .save {
        width: 100%;
        border-radius: 9px;
        background-color: $purple;
        color: $white;

        &:hover {
            background-color: $purple;
        }
    }

    .job {
        display: flex;
        justify-content: space-between;

        .left,
        .right {
            display: flex;
            flex-direction: column;
            width: 50%;
        }

        .left {
            margin-right: 25px;
        }
    }

    .input {
        width: 100%;
        border: 1px solid $border;
        background-color: $body;
        height: 40px;
        border-radius: 3px;
        text-indent: 12px;
    }

    .dropdown {
        width: 100%;
        border-radius: 3px;
        font-size: 14px;

        fieldset {
            outline: none;
            border: none;
        }

        &.month {
            width: 140px;
            margin-right: 0.8rem;
        }

        &.year {
            width: 110px;
        }
    }

    &__add {
        form {
            .label {
                font-size: 14px;
                font-weight: 400;
                color: $black;
                display: block;
                margin-bottom: 5px;
            }

            .field {
                position: relative;
            }

            .bottom {
                display: flex;
                justify-content: space-between;

                .left {
                    display: flex;
                    align-items: center;
                }

                .right {
                    display: flex;
                    align-items: center;
                }
            }

            .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
                font-size: 14px;
            }
        }
    }
}