@import '../../../assets/variables/colors.scss';

/* width */
.skills-popup-side::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.skills-popup-side::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px $gray;
  border-radius: 10px;
}

/* Handle */
.skills-popup-side::-webkit-scrollbar-thumb {
  background: $purple;
  border-radius: 10px;
}

.skill {
  .complete {
    width: 100%;
  }

  .err {
    border: 1px solid $warning;
  }

  .skills {
    max-height: 380px;
    margin-bottom: 20px;
    overflow-y: auto;
    padding-right: 15px;
  }

  .top {
    display: flex;
    margin-top: 25px;
  }

  .field {
    margin-bottom: 20px;

    .level {
      margin-top: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .remove {
        cursor: pointer;
        transform: translate(5px);
        margin-top: 10px;
      }
    }

    &.input-field {
      margin-right: 44px;
      width: 100%;

      .field {
        width: 100%;
      }
    }

    .label {
      color: $black;
      font-size: 14px;
      font-weight: 400;
      display: block;
      margin-bottom: 5px;
      &.label-err {
        color: $warning;
      }
    }

    .field-and-level {
      display: flex;
    }

    .input {
      height: 40px;
      border: none;
      border-radius: 5px;
      background-color: $body;
      border: 1px solid $border;
      text-indent: 12px;
      outline: none;
      width: 100%;
    }

    .dots {
      width: 110px;

      .dot {
        width: 10px;
        height: 10px;
      }
    }
  }

  .add-btn {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .add-skill {
      color: $black;
      font-size: 14px;
      font-weight: 400;
      display: block;
      margin-left: 10px;
    }
  }

  .save {
    width: 100%;
    background-color: $purple;
    border-radius: 9px;
    height: 47px;
    color: $white;
    margin-top: 40px;

    &.no-margin {
      margin-top: 0;
    }

    &:hover {
      background-color: $purple;
    }
  }
}
