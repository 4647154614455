@import '../../../../assets/variables/colors.scss';

/* width */
.plan::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.plan::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.plan::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.plan {
    margin-top: 31px;
    overflow-y: auto;
    height: 600px;
    background: $white;

    &__options {
        display: flex;
        justify-content: space-between;
    }

    &__ssl_info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        &-btn {
            background-color: $purple !important;
            width: 290px;
            color: $white !important;
            height: 47px;
            border-radius: 9px !important;
            transform: translateX(45px);
        }
    }

    &__accept {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-title {
            color: $black;
            font-size: 24px;
            font-weight: 600;
            margin: 40px 0;
            display: block;
        }

        &__banks {
            display: flex;
            align-items: center;

            &-image {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                padding: 12px;
                border-radius: 30px;
                box-shadow: 0px 1.818px 29px 0px $shadow;
                margin-right: 32px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }



    .premium-btn {
        background-color: $white;
        border: 1px solid $white;
        width: 100%;
        height: 47px;
        border-radius: 9px;
        color: $light-blue;
        font-weight: 600;
        font-size: 16px;
        margin-top: 20px;

        &:hover {
            color: $white;
        }
    }

    .pro-btn {
        background-color: $light-blue;
        border: 1px solid $light-blue;
        width: 100%;
        height: 47px;
        border-radius: 9px;
        color: $white;
        font-weight: 600;
        font-size: 16px;
        margin-top: 20px;

        &:hover {
            color: $light-blue;
        }
    }
}

.option {
    .option-title {
        color: $black;
        text-transform: capitalize;
    }
}