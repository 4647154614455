@import '../../../../assets/variables/colors.scss';

.assessment {
    &-paragraph {
        margin-top: 35px;
        margin-bottom: 35px;
        color: $black;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        &-btn {
            height: 45px;
            border-radius: 9px !important;

            &.back {
                transition: all .5s;
                background-color: $storm-white;
                width: 186px;
                color: $purple;
                margin-right: 20px;
                border: 1px solid $storm-white;

                &:hover {
                    border: 1px solid $purple;
                    color: $purple;
                    background-color: $storm-white;
                }
            }

            &.start {
                width: 263px;
                background-color: $purple;
                color: $white;

                &:hover {
                    border: 1px solid $purple;
                    color: $purple;
                    background-color: $storm-white;
                }
            }
        }
    }
}