@import '../../../assets/variables/colors.scss';

table {

    th,
    td,
    tr {
        border: 0 !important;
    }
}

.row {
    background-color: $row;
}