@import '../../../assets/variables/colors.scss';

.range {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    justify-content: space-between;

    &__slider {
        position: relative;
        height: 10px;

        &-point {
            display: block;
            width: 35px;
            height: 35px;
            border: 3px solid $white;
            border-radius: 50%;
            background-color: $purple;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(11px, -13px);
        }
    }

    &-label {
        font-size: 18px;
        font-weight: 500;
        color: $black;
        margin-bottom: 15px;
    }

    .range-label {
        display: block;
        height: 60px;
    }

    .range {
        position: relative;

    }

    .track {
        background-color: $storm-white;
        height: 10px;
        width: 100%;
        border-radius: 50px;
        position: relative;

        &.max {
            .slider-container {
                transform: translateX(-22px);
            }
        }

        .slider-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            top: -28px;
            transform: translateX(-22px);
        }
    }

    .active-track {
        background-color: $purple;
        width: 100%;
        height: 10px;
        border-radius: 50px;
    }

    .inputRange {
        width: 35px;
        height: 35px;
        border: 3px solid $white;
        border-radius: 50%;
        background-color: $purple;
        cursor: pointer;
        left: 90;
    }

    .max-label {
        position: absolute;
        top: -32px;
        right: 0;

        &.disable {
            display: none;
        }
    }

    .value-label {
        transform: translateY(-4px);
    }

    .min-label {
        position: absolute;
        top: -32px;
        left: 0;

        &.disable {
            display: none;
        }
    }
}
