@import '../../assets/variables/colors.scss';

.result-content {
  width: 100%;

  .results-container {
    background-color: $white;
    width: 100%;
    max-width: 1500px;
    border-radius: 1px;
    padding: 25px 25px 20px 15px;
    height: fit-content;

    .title {
      color: $black;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 30px;
    }

    .candidate {
      padding: 17px 25px;
      box-shadow: 0px 1px 103px 0px rgba(141, 156, 174, 0.25);
      display: flex;
      border-radius: 13px;
      margin-bottom: 15px;

      .score {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;

        .score-text {
          color: $role-color;
          font-size: 14px;
          font-weight: 500;
          height: fit-content;
        }
      }

      .buttons {
        flex: 0.7;
        display: flex;
        gap: 24px;
        align-items: center;
        justify-content: flex-end;

        .button {
          cursor: pointer;
        }
      }

      .profile-info {
        display: flex;
        flex: 1.5;
        gap: 14px;
        color: $black;
        line-height: normal;

        .name {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 7px;
        }

        .bio {
          font-size: 12px;
          font-weight: 300;
          opacity: 0.7;
          max-width: 235px;
          display: inline-block;
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    max-width: 1500px;

    .arrowNext,
    .arrowPrev {
      width: 64px;
      height: 64px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--Gray, #bcbcbc);
      color: $purple;
      font-size: 20px;
      font-weight: 800;
      cursor: pointer;
    }

    .page {
      width: 64px;
      height: 64px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $purple;
      font-size: 18px;
      font-weight: 800;
      background: $storm-white;
      color: $purple;
    }
  }
}
