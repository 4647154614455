@import '../../../assets/variables/colors.scss';

.faqItem {
    height: 162px;
    padding: 52px 60px;
    background-color: $item;

    &.active {
        background-color: $active-item;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;


        &-index {
            color: $index;
            font-size: 48px;
            font-weight: 700;
        }

        &__between {
            display: flex;
            flex-direction: column;
            width: 90%;
            padding: 0 50px;

            &-header {
                font-size: 24px;
                font-weight: 600;

                &-active {
                    color: $purple;
                }
            }

            &-plus {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: $white;
                cursor: pointer;

                &.active {
                    background-color: $purple;
                }
            }

            &-desc {
                margin-top: 20px;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: $faq-desc;
            }
        }
    }
}