@import '../../../assets/variables/colors.scss';

.option {
  width: 330px;
  box-shadow: 0px 6px 95.7px 0px rgba(232, 235, 236, 0.2);
  border-radius: 10px;
  padding: 35px 40px;
  background-color: $white;
  height: fit-content;

  .option-button {
    width: 100%;
    background-color: $light-blue !important;
    padding: 12px 22px;
    font-size: 16px;
    color: $white;
    border-radius: 9px;
    margin-top: 10px;
  }

  &.active {
    background-color: $light-blue;

    .option-button {
      background-color: $white !important;
      color: $light-blue;
      margin-top: 24px;
    }

    span,
    img,
    p {
      filter: brightness(0) invert(1);
    }
  }

  &-title {
    font-weight: 600;
    font-size: 18px;
    display: block;
    margin-bottom: 15px;
    text-transform: capitalize;
  }

  &-price {
    font-size: 30px;
    font-weight: 600;
    color: $black;
  }

  &-month {
    color: $dark-gray;
    font-size: 12px;
    font-weight: 600;
  }

  &-attention {
    color: $brown-gray;
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin-top: 15px;
  }

  &__options {
    margin: 0;
    padding: 0;
    margin-top: 37px;

    &__list {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &-option {
        font-weight: 600;
        font-size: 12px;
        margin-left: 13px;
      }
    }

    &-button {
      width: 100%;
      background-color: $sky-blue;
      font-size: 16px;
      font-weight: 500;
      border-radius: 9px;
    }
  }
}
