@import '../../../assets/variables/colors.scss';

/* width */
.extra_activities::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.extra_activities::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px $gray;
  border-radius: 10px;
}

/* Handle */
.extra_activities::-webkit-scrollbar-thumb {
  background: $purple;
  border-radius: 10px;
}

.about__right__bottom__left__info {
  padding-bottom: 10px;
}

.extra_activities {
  max-height: 100px;
  overflow-y: auto;
  padding-right: 15px;
}
