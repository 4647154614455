@import '../../assets/variables/colors.scss';

.field {
    position: relative;
    margin-bottom: 20px;
    background-color: $white;

    .error {
        border: 1px solid $warning !important;
        border-radius: 3px;
    }

    .fmessage {
        font-size: 12px;
        color: $warning;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(0, 130%);
        background-color: $body;
    }
}

.input-container {
  position: relative;
 height: 100%;
}

.toggle-password-button {
  position: absolute; 
  right: 20px; 
  top: 50%; 
  transform: translateY(-50%); 
  background: transparent; 
  border: none; 
  cursor: pointer; 
  color: $purple;; 
}