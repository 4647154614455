@import '../../assets/variables/colors.scss';
@import '../../assets/variables/mixins.scss';

.up {
  margin: auto;
  width: 490px;
  @include mobile {
    width: 100%;
  }
  &__desc {
    color: $black;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 25px;
    text-align: center;
  }

  &__action {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    &-pricing {
      color: $purple;
      font-size: 16px;
      font-weight: 500;
      margin-top: 20px;
    }
  }

  &__social {
    display: flex;
    flex-direction: column;

    .up__social-btn {
      background-color: $sky-blue;
      color: $white;
      font-weight: 500;
      font-size: 16px;
      border-radius: 9px;
      margin-bottom: 15px;
      height: 47px;

      .btn-icon {
        margin-right: 10px;
      }

      &:hover {
        background-color: $sky-blue;
      }

      &.in {
        background-color: $blue;

        &:hover {
          background-color: $blue;
        }
      }
    }

    &__terms {
      width: 315px;
      margin: auto;
      font-size: 12px;
      color: $black;
      font-weight: 400;
      text-align: center;

      &-term {
        color: $sky-blue;
        padding-left: 5px;
      }
    }

    &__relevent {
      width: 315px;
      margin: auto;
      margin-top: 11px;
      font-size: 12px;
      color: $black;
      font-weight: 400;
      text-align: center;
    }
  }

  form {
    margin-top: 25px;

    .field {
      width: 100%;
      height: 40px;
      border-radius: 3px;
      border: 1px solid $border;
      background-color: $body;
      margin-bottom: 20px;

      .inputs {
        width: 100%;
        height: 100%;
        text-indent: 15px;
        outline: none;
        border: none;
        background-color: transparent;
        color: $black;
      }
    }

    .submit-button {
      border-radius: 9px;
      background-color: $purple;
      height: 47px;
      text-align: center;
      color: $white;
      width: 100%;
      margin-top: 25px;
      font-weight: 500;
    }
  }
}

.activations {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
