@import '../../../../assets/variables/colors.scss';

.confirm {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;

  &__desc {
    font-weight: 400;
    font-size: 16px;
    color: $black;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 25px;

    &-btn {
      width: 223px;
      height: 47px;
      font-size: 16px;
      color: $white;
      border-radius: 9px;
      font-weight: 500;
      color: $white !important;

      &.no {
        background-color: $warning;
        margin-right: 10px;
        border-radius: 9px;

        &:hover {
          color: $warning !important;
          border: 1px solid $warning;
        }
      }

      &.yes {
        background-color: $approve;
        border-radius: 9px;

        &:hover {
          color: $approve !important;
          border: 1px solid $approve;
        }
      }
    }
  }
}
