@import '../../assets/variables/colors.scss';

.reviewCart {
  max-width: 414px;
  width: 100%;
  min-height: 300px;
  height: auto;
  border-radius: 5px;
  padding: 40px 45px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9fd;
  border: 1px solid #EBDFF8;
  border-radius: 6px;
  word-break: break-all;
  justify-content: space-between;

  .description {
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    word-break: initial;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }

  .starContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    column-gap: 7px;
  }

  .aboutPerson {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 25px;
    .round{
      border-radius: 50%;
      object-fit: cover;
    }

    .fullNameText {
      margin-top: 5px;
      font-weight: 500;
      font-size: 18px;
    }
    .positionText {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}
