.full {
  background-color: white;
  width: 800px;

  .profile {
    text-align: center;
    font-weight: 500;
    margin: 20px 0;
  }

  .chart-radar-full {
    background-color: white;
  }

  .charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 30px;
    gap: 50px;

    &.pdf-second-charts {
      justify-content: flex-start;
    }
  }

  .chart-data-show {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .chart-title {
    font-weight: bold;
  }
}
