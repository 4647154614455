@import '../../../assets/variables/colors.scss';

.c-header {
  background-color: $purple;
  width: 800px;
  height: 200px;
  display: flex;
  align-items: center;
  padding: 20px 100px;

  .name-surname {
    color: $white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    .name {
      font-size: 26px;
    }

    .role {
      font-size: 16px;
      display: block;
    }
  }
}
