@import '../../../../assets/variables/colors.scss';

.pop {
  margin-top: 25px;

  &__radios {
    &-title {
      font-weight: 600;
      font-size: 16px;
      color: $black;
      margin-bottom: 8px;
      display: block;
    }
  }

  &__choose {
    &-title {
      color: $black;
      display: block;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      margin-top: 40px;
    }

    &-total {
      display: block;
      text-align: center;
      margin: 16px 0;
      strong {
        font-weight: 600;
      }
    }

    &__items {
      display: flex;
      align-items: center;
      justify-content: center;

      &-method {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 55px;
        width: 100px;
        height: 100px;
        border-radius: 30px;
        background-color: $white;
        box-shadow: 0px 1.818px 29px 0px rgba(143, 151, 153, 0.2);
      }
    }
  }
}
