.radio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right_text {
        font-weight: 700;
        font-size: 16px;
    }

}

.member-radio {
    width: 100%;
}