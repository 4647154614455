@import '../../../../assets/variables/colors.scss';

.overview__bar::-webkit-scrollbar {
  width: 10px;
}

.overview__bar::-webkit-scrollbar-track {
  box-shadow: $white;
}

.overview__bar::-webkit-scrollbar-thumb {
  background: $purple;
  border-radius: 10px;
}

.overview {
  display: flex;
  margin-top: 25px;

  &__bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 400px;
    padding-right: 30px;
    gap: 3rem;

    &_title {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 15px;
    }
    .bar {
      width: 304px;
      width: 234px;
      &_box {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .min_max {
          font-size: 11px;
          color: #808080;
        }
      }
      &__label {
        font-size: 12px;
        font-weight: 400;
        color: $black;
        display: block;
        margin-bottom: 5px;
        margin-left: 15px;
      }

      &__line {
        width: 100%;
        height: 26px;
        background-color: $light-gray;
        position: relative;
        border-radius: 32px;

        &-progress {
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(0, 17%);
          border-radius: 32px;
          background-color: #522583;
          height: 20px;
          color: $white;
          text-align: center;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .bar {
      margin-bottom: 25px;
    }
  }
}
