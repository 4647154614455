@import '../../../assets/variables/colors.scss';

.container_block {
  margin: 0 50px 50px 50px;

  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .loading {
      color: #522583;
    }
  }

  .popup-add-new {
    max-width: 976px;
    padding: 40px;
    max-height: 1181px;
    margin: 20px 0;
    .popup__desc {
      max-width: 916px;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    .arrowNext,
    .arrowPrev {
      width: 64px;
      height: 64px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--Gray, #bcbcbc);
      color: $purple;
      font-size: 20px;
      font-weight: 800;
      cursor: pointer;
    }

    .page {
      width: 64px;
      height: 64px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $purple;
      font-size: 18px;
      font-weight: 800;
      background: $storm-white;
      color: $purple;
    }
  }

  .cart_item {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
  }

  .culture_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 60px;
    margin-top: 55px;
    margin-right: 70px;

    .search_bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;

      .search_input {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
        padding: 10px 20px;
        width: 330px;
        border-radius: 105px;
        column-gap: 10px;

        input:focus-visible {
          outline: none;
        }

        .search_input_field {
          width: 100%;
          border: none;
          background-color: transparent;
          color: #2a2a2a;
        }
      }
    }

    .block {
      background-color: $white;
      padding: 44px 35px;
      width: 50%;
    }

    &__left {
      border-radius: 6px;

      &-title {
        font-size: 24px;
        color: $black;
        font-weight: 600;
      }

      &-ranges {
        padding: 0 30px;

        &-range {
          margin-top: 30px;
        }
      }
    }

    &__right {
      margin-left: 20px;
      display: flex;
      flex-direction: column;

      &-chart {
        canvas {
          margin: auto;
          width: 616px;
          height: 490px;
        }
      }

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;

        &-comp {
          background-color: rgba(82, 37, 131, 0.12) !important;
          color: $purple !important;
        }

        &-save {
          background-color: $purple !important;
          color: $white !important;
          margin-left: 15px !important;
        }

        .btn {
          width: 258px !important;
          height: 47px !important;
          border-radius: 9px !important;
        }
      }
    }
  }
}
