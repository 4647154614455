@import '../../assets/variables/colors.scss';

.cultureContainer {
  margin-top: 50px;
  overflow: hidden;

  .overview__bar {
    padding-right: 0px !important;
  }
}

.MuiPaper-elevation {
  box-shadow: none !important;
}

.MuiAccordionSummary-root {
  max-height: 47px;
}

.MuiPaper-elevation::before {
  display: none;
}

.bar_box {
  width: 95%;
}
