@import '../../assets/variables/colors.scss';

.jobOpeningCart {
  word-break: break-all;
  max-width: 434px;
  min-height: 443px;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0px 1.72px 177.39px 0px rgba(141, 156, 174, 0.25);

  .cartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dateContainer {
      width: 140px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $active-item;
      border-radius: 37px;
      color: $text-gray;
    }
  }

  .cartBody {
    .typeText {
      font-size: 14px;
      color: $purple;
      margin-top: 5px;
    }

    .typeTitle {
      font-size: 28px;
      margin-top: 5px;
    }

    .typeDescription {
      margin-top: 10px;
      color: $text-gray;
    }

    .categories {
      display: flex;
      column-gap: 5px;
      flex-wrap: wrap;
      margin-top: 15px;
      row-gap: 10px;

      .categoryItem {
        background-color: rgba(82, 37, 131, 0.1);
        color: $purple;
        max-width: fit-content;
        padding: 10px 15px;
        border-radius: 28px;
        font-size: 14px;
      }
    }
  }

  .cartFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .next {
      height: 50px;
      max-width: 140px;
      background-color: $purple;
      color: $white;
      width: 170px;
      border-radius: 10px;

      &:hover {
        background-color: $white;
        border: 1px solid $purple;
        color: $purple;
      }
    }

    p {
      font-size: 22px;
    }
    span {
      font-size: 12px;
      color: $black;
    }
  }
}
