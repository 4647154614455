.activations {
  min-height: 100vh;
  display: flex;
  align-items: center;

  &.activation-pref {
    min-height: auto;
  }

  .edu-activation {
    width: 840px;
    padding: 0 60px 40px;

    .left-edu {
      width: 100%;
      gap: initial;

      .field {
        width: 100%;
        margin: 0px;

        &.start-field {
          margin: 0 20px;

          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }
}
