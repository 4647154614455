@import '../../assets/variables/colors.scss';

.organization {
  gap: 0px;
  padding: 0px 10px 0 0 !important;
  height: 100%;
  overflow-y: auto !important;

  .sharedContainer {
    max-width: 100%;
    .drag-and-drop_image {
      max-width: 100%;
    }
  }

  .addJob {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 30px 0px;
  }
  .field{
    margin-bottom:5px;
    margin-top: 10px;
  }

  .fields {
    margin-top: 0px;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 5px;

    .label {
      margin-bottom: 0px;
    }
    .salary_label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .fields_flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 27px;
    margin-bottom:5px;

    .many {
      margin-left: 0px;
    }
  }
  .next {
    height: 50px;
    max-width: 180px;
    background-color: $purple;
    color: $white;
    width: 170px;
  }

  .imagePickerContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
  }
  .button_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .btn_container {
    width: 100%;
    margin-top: 30px;
    .one-btn {
      max-width: 100%;
      width: 100%;
    }
  }
}
