$purple: #522583;
$lighter-purple: rgba(82, 37, 131, 0.36);
$dark-purple: #3d156a;
$white: #ffffff;
$green: #258378;
$sky-blue: #1976d2;
$light-blue: #33b1e8;
$blue: #0077b5;
$dark-black: #000000;
$black: #2a2a2a;
$gray: #949494;
$light-purple: rgba(82, 37, 131, 0.07);
$soft-purple: #5225835c;
$border: #efedf2;
$body: #f6f5fa;
$light-rose: #e1dbe7;
$backgdrop: #00000028;
$light-gray: #f1f5f6;
$storm-gray: #676768;
$dark-gray: #656565;
$brown-gray: #817575;
$warning: #e45353;
$rose: #f8aeae;
$approve: #0fb398;
$background: #efebf4;
$storm-white: #eae5f0;
$shadow: rgba(141, 156, 174, 0.25);
$passed: #0bb497;
$item: #f9f9fd;
$active-item: #f5f1fc;
$index: #9d96a8;
$faq-desc: #5a5365;
$row: #eef5f8;
$action-purple: rgba(82, 37, 131, 0.41);
$action-rose: #f8aeae;
$action-blue: #bed7fe;
$action-green: #a0e5d5;
$role-blue: rgba(0, 158, 226, 0.1);
$role-color: #009ee2;
$light-silver: #fdfdfd;
$light-gray-100: #b1b2c2;
$border-gray: #bcbcbc;
$text-gray: #979797;
