@import '../../assets/variables/colors.scss';
@import '../../assets/variables/mixins.scss';

.reset {
    form {
        width: 406px;
        margin-top: 20px;
        margin: auto;
        @include mobile {
            width: 100%;
        }
        .field {
            width: 100%;
            height: 40px;
            border: 1px solid $border;
            background-color: $body;
            border-radius: 3px;
            margin-bottom: 0;

            .fmessage {
                transform: translate(12%, 125%);
            }

            input {
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                text-indent: 15px;
                background-color: transparent;
                color: $black;


            }
        }

    .login-btn {
      width: 100%;
      border-radius: 9px;
      height: 47px;
      color: $white;
      background-color: $purple;
      margin-top: 25px;
    }
  }
  &_inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
