@import '../../../assets/variables/colors.scss';
@import '../../../assets/variables/mixins.scss';

.tab {
  margin-top: 20px;

  .disable-edit {
    .edit {
      &:last-child {
        opacity: 0.8;
        pointer-events: none;
      }
    }
  }

  .certification-skill {
    font-size: 20px;
    font-weight: 500;
    color: $black;
    opacity: 0.8;
    display: block;
    margin-bottom: 12px;
  }

  .certification-skill-desc {
    font-size: 14px;
  }

  &.experience {
    flex-direction: column;
  }

  &__education {
    width: 100%;
  }

  &__top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    @include tablet {
      display: flex;
      flex-direction: column;
    }
  }

  &__education {
    min-height: 194px;

    &.education {
      .tab__content__header-title {
        color: $black;
      }
    }
  }

  &__content {
    max-width: 560px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 430px;
      width: 90%;

      &-title {
        color: $purple;
        font-size: 18px;
        font-weight: 500;
      }

      &-years {
        color: $black;
        opacity: 0.8;
        font-size: 14px;
      }
    }

    &__desc {
      max-width: 350px;
      margin-bottom: 12px;

      &-text {
        color: $black;
        opacity: 0.8;
        font-size: 14px;
      }

      margin-top: 7px;
      margin-bottom: 12px;
    }

    &__about {
      display: flex;
      align-items: center;
      width: 90%;
      justify-content: start;
      gap: 16px;

      .item {
        width: 33%;
      }
    }
  }

  &__bottom {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    @include tablet {
      display: flex;
      flex-direction: column;
    }
    .education {
      .tab__content__header-title {
        color: $black;
      }
    }

    &__content {
      .tab__content {
        max-width: 96%;
      }

      &__items {
        display: flex;
        flex-direction: column;

        &-title {
          font-size: 18px;
          color: $black;
          display: block;
          margin-bottom: 7px;
          font-weight: 500;
        }

        &-desc {
          font-size: 14px;
          color: $black;
          font-weight: 400;
        }
      }
    }
  }
}
