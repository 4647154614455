@import '../../assets/variables/colors.scss';
@import '../../assets/variables/mixins.scss';

.footer {
    height: 75px;
    padding: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $purple;
    margin-top: auto;

    @include tablet {
        flex-direction: column;
        height: auto;
    }

    &__left {
        display: flex;
        align-items: center;

        @include tablet {
            margin-bottom: 15px;
        }

        @include mobile {
            flex-direction: column;
        }

        &-item {
            margin-right: 40px;
            display: block;
            color: $white;
            text-decoration: none;

            @include mobile {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;

        &-item {
            display: block;
            padding: 5px;
            margin-right: 20px;
        }
    }
}