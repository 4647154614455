@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'styles/utils/reset';
@import 'assets/variables/colors.scss';
@import 'assets/variables/mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&family=Work+Sans&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Rubik', sans-serif !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

html,
body {
    @include tablet {
        overflow-x: hidden !important;
    }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.s-hidden {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
}

.m-hidden {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
}

.customAutocomplete {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;

    .MuiFormControl-root {
        .MuiInputBase-root {
            padding: 0 !important;

            fieldset {
                outline: none !important;
                border: 1px solid $border !important;
            }

            input {
                text-indent: 12px;
                font-size: 14px;
            }
        }
    }
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  text-transform: initial !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

html,
body {
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto !important;
    background-color: $body;
}

a {
    color: inherit;
    text-decoration: none;
}

.invalid-btn {
    pointer-events: none !important;
    opacity: 0.8 !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.linkedin-share {
    .react-share__ShareButton {
        background-color: #0077B5 !important;
    }
}


@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }
}
