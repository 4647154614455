@import '../../assets/variables/colors.scss';
@import '../../assets/variables/mixins.scss';

.pricingPopup {
  background-color: #fafafa;
  width: 1116px;
  max-width: 1116px;
}

.upgradePlanPopup {
  width: 631px;
  max-width: 631px;
}

.plan-modal {
  max-width: 1150px;
  width: 100%;
  overflow: hidden;
  height: 95vh;
  padding-bottom: 56px;
  max-height: 1100px;
  border-radius: 21px;
  border-radius: 21px;
  background: linear-gradient(0deg, #FDFDFD 0%, #FDFDFD 100%), #FFF;

  .plan {
    height: 100%;
  }
}

.membership {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 75px;

  .navigation_section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .back_button {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $purple;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }

    .membership_navigate {
      display: flex;
      align-items: center;
      gap: 10px;

      .membership_item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        gap: 5px;
        color: $purple;
        font-size: 16px;
        font-weight: 500;
        background-color: $storm-white;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .main_section {
    width: 100%;
    height: calc(100% - 75px);
    overflow: hidden;
  }
}

.footer_section {
  width: 100%;
  position: fixed;
  bottom: 0;
}
