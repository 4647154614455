@import '../../assets/variables/colors.scss';
@import '../../assets/variables/mixins.scss';

.content {
  width: 841px;
  min-height: 400px;
  padding: 34px 44px;
  background-color: $white;
  border-radius: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @include tabletXS {
    width: 100%;
  }
  &-title {
    font-weight: 600;
    font-size: 24px;
    color: $black;
    display: block;
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: center;
  }
  &__arrow {
    color: $purple;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    &-text {
      display: block;
      margin-left: 10px;
    }
  }
  &__middle {
    text-align: center;
    &__switcher {
      display: flex;
      align-items: center;
      width: 235px;
      height: 34px;
      border-radius: 32px;
      background-color: $background;
      padding: 5px;
      margin: auto;
      position: relative;
      &__button {
        background-color: $purple;
        transition: transform 0.5s ease;
        position: absolute;
        width: 50%;
        height: 80%;
        border-radius: 12px;

        &.left {
          transform: translate(-2px, 0);
        }
        &.right {
            transform: translate(93%, 0);
        }
      }
      &-item {
        display: block;
        width: 50%;
        color: $lighter-purple;
        padding: 5px 26px;
        border-radius: 45px;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        white-space: nowrap;
        z-index: 100;
        transition: transform 0.5s ease;
        &.active {
          color: $white;
        }
      }
    }
  }
}
