.compInfo {
  width: 100%;
  max-width: 900px;
  padding: 40px 35px 30px;
  box-shadow: inset 15px 650px 0px rgba(141, 156, 174, 0.05);

  &__container {
    &__subtitle {
      text-align: center;
      margin-top: 15px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .MuiTableContainer-root {
      margin-top: 20px;
      box-shadow: none;
    }

    &__table {
      min-width: initial;
      background: #fff;

      tbody {
        .MuiTableRow-root {
          margin-top: 3px;
        }
      }

      .MuiTableRow-root {
        gap: 10px;
        background: none;

        .MuiTableCell-root {
          width: 45%;
          border-radius: 5px;
          background: #fff;
          box-shadow: 3px 0 150px 0px rgba(141, 156, 174, 0.25);
          text-align: center;

          &:first-child {
            width: 25%;
          }

          &:last-child {
            width: 30%;
          }
        }
      }
    }
  }
}
