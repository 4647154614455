@import '../../assets/variables/colors.scss';

.edit {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: $light-purple;
    display: block;
    cursor: pointer;
    position: relative;

    &-img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
