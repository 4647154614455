@import '../../assets/variables/colors.scss';

.cart_container {
  max-width: 330px;
  width: 100%;

  .cart_body {
    margin-top: 5px;
    padding: 24px 20px;
    background-color: $white;
    border-radius: 5px;

    .button {
      min-width: 100%;
      margin-top: 15px;
      height: 47px;
    }
  }

  .cart_header {
    height: 50px;
    padding: 0px 20px;
    background-color: $white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon_container {
      display: flex;
      align-items: center;
      column-gap: 10px;

      .pencil_icon,
      .trash_icon {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
      }

      .pencil_circle {
        background-color: #522583;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        position: absolute;
        opacity: 7%;
      }

      .trash_circle {
        background-color: #e45354;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        position: absolute;
        opacity: 7%;
      }
    }
  }
  .cart_title {
    color: #2a2a2a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1 auto;
    line-height: 1.2;
  }
}
