@import '../../../assets/variables/colors.scss';

.message {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 2px;

    &-title {
        font-size: 14px;
        font-weight: 600;
        color: $black;
    }

    &-subtitle {
        font-size: 14px;
        font-weight: 400;
        color: $purple;
        margin: 8px 0;
        display: block;
    }

    &-date {
        color: $gray;
        font-weight: 400;
        font-size: 12px;
    }
}