@import '../../../../assets/variables/colors.scss';

/* width */
.descs::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.descs::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.descs::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.certification {
    overflow-y: auto;
    max-height: 582px;
    padding: 0 10px;

    .err {
        border: 1px solid $warning;
        border-radius: 4px;
    }

    .add-experience {
        display: flex;
        align-items: center;
        margin: 25px 0;
        width: max-content;
        cursor: pointer;

        .action-add {
            width: 36px;
            height: 36px;
        }

        .add-experience-title {
            font-size: 14px;
            font-weight: 400;
            color: $black;
            display: block;
            margin-left: 10px;
        }
    }

    &__all {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;
        margin-top: 30px;

        &__left {
            width: 80%;

            &-title {
                color: $black;
                font-size: 18px;
                font-weight: 600;
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                justify-content: space-between;

                &-year {
                    color: $black;
                    opacity: 0.8;
                    font-size: 14px;
                    display: inline-block;
                    margin-left: 60px;
                }
            }

            &-type {
                font-size: 12px;
                color: $black;
                opacity: 0.8;
            }
        }

        &__right {
            display: flex;
            align-items: center;

            &-action {
                cursor: pointer;
                padding: 5px;
                margin-right: 15px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

    }

    &__form {
        .field {
            display: flex;
            flex-direction: column;
            margin-bottom: 18px;

            .input {
                background-color: $body;
                height: 40px;
                border: 1px solid $border;
                outline: none;
                text-indent: 14px;
            }

            .label {
                font-size: 14px;
                color: $black;
                display: block;
                margin-bottom: 5px;
            }
        }

        .save {
            border-radius: 9px;
            color: $white;
            background-color: $purple;
            font-weight: 500;
            width: 100%;

            &:hover {
                color: $white !important;
                background-color: $purple !important;
            }
        }
    }
}