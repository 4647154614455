@import '../../../../assets/variables/colors.scss';

.activationWelcome {
    &__content {
        &-message {
            color: $black;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 20px;
            text-align: center;
            line-height: 21px;
        }

        &-btn {
            width: 100%;
            height: 47px;
            border-radius: 9px !important;
            background-color: $purple !important;
            color: $white !important;
            font-weight: 500 !important;
            font-size: 16px !important;
            border: 1px solid $purple !important;

            &:hover {
                background-color: $white !important;
                color: $purple !important;
            }
        }
    }
}