@import '../../../assets/variables/colors.scss';

.jCard {
  width: 100%;
  height: 109px;
  box-shadow: 0px 1px 103px 0px $shadow;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 13px;
  padding: 32px 20px;

  &__left {
    display: flex;
    align-items: center;

    &-image {
      width: 75px;
      height: 75px;
      border-radius: 50%;
    }

    &__info {
      display: flex;
      flex-direction: column;
      margin-left: 17px;
      gap: 5px;
      &_name {
        display: flex;
        gap: 12px;
        align-items: baseline;
      }

      &-status {
        max-width: 128px !important;
        height: 24px !important;
        background-color: #0bb497 !important;
        color: #ffffff !important;
        border-radius: 10px !important;
        padding-left: 5px !important;
        font-size: 12px !important;
        .MuiInput-input {
          padding: 0 !important;
        }
        &::before {
          content: none !important;
          border: none !important;
        }
        &::after {
          content: none !important;
          border: none !important;
        }
      }
      &_industry {
        background-color: #f9f9fd;
        color: #009ee2;
        font-size: 12px;
        border-radius: 10px;
        padding: 10px;
      }

      &-title {
        color: $black;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        &-type {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          margin-left: 5px;
          border-radius: 5px;
          background: rgba(0, 158, 226, 0.1);
          min-width: 65px;
          height: 22px;
          color: $role-color;
          font-size: 12px;
        }
      }

      &-sub {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        opacity: 0.7;
        max-width: 230px;
      }
      .new_application {
        background-color: #e75050;
        color: #ffffff;
        max-width: 128px ;
        height: 24px ;
        border-radius: 10px ;
        padding: 5px ;
        font-size: 12px ;
      }
    }
  }

  &__middle {
    display: flex;
    align-items: flex-start;

    &__matches {
      display: flex;
      flex-direction: column;

      &-title {
        opacity: 0.7;
        font-weight: 500;
        font-size: 14px;
        color: $sky-blue;
        display: block;
        margin-bottom: 10px;
      }
    }

    &__details {
      display: flex;
      margin-left: 50px;

      &__item {
        display: flex;
        flex-direction: column;
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }

        &-title {
          font-weight: 500;
          font-size: 14px;
          color: $black;
          opacity: 0.7;
          display: block;
          margin-bottom: 10px;
        }

        &-desc {
          font-size: 14px;
          color: $black;
          opacity: 0.7;
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;

    &__actions {
      display: flex;

      &-action {
        display: block;
        margin-right: 20px;
        cursor: pointer;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
  .down-arrow {
    cursor: pointer;
    margin: 0 !important;
    transform: translate(-15px) !important;
  }
}
