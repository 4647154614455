@import '../../../assets/variables/colors.scss';

.organization {
  gap: 0px;
  padding: 0px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  .error-ms {
    color: $warning;
    font-size: 12px;
  }

  .sharedContainer {
    max-width: 100%;
    .drag-and-drop_image {
      max-width: 100%;
    }
  }

  .addJob {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 30px 0px;
  }

  .fields {
    margin-top: 0px;
    gap: 10px;
    margin-right: 0px;
    margin-bottom: 25px;

    .label {
      margin-bottom: 0px;
    }
    .salary_label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .fields_flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 27px;

    .many {
      margin-left: 0px;
    }
  }
  .next {
    height: 50px;
    max-width: 180px;
    background-color: $purple;
    color: $white;
    width: 170px;
  }

  .imagePickerContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
  }
  .button_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.selectedImages {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}
.imageContainer {
  display: flex;
  gap: 12px;
  .png {
    height: 38px;
    width: 38px;
  }
  .removeImage {
    height: 24px;
    width: 24px;
    margin-left: 17px;
  }
  .bar {
    width: 100%;
    height: 7px;
    &_labels {
      display: flex;
      justify-content: space-between;
    }
    &__scale {
      font-size: 12px;
      color: #727C8B;
    }
    &_box {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .min_max {
        font-size: 11px;
        color: #808080;
      }
    }
    &__label {
      font-size: 16px;
      font-weight: 400;
      color: $black;
      display: block;
      margin-bottom: 5px;
    }

    &__line {
      width: 100%;
      height: 7px;
      background-color: $light-gray;
      position: relative;
      border-radius: 32px;

      &-progress {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0, 17%);
        border-radius: 32px;
        background-color: #522583;
        height: 7px;
        color: $white;
        text-align: center;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.btn_container {
  width: 100%;
  margin-top: 30px;
  .one-btn {
    max-width: 100%;
    width: 100%;
  }
}
