@import '../../../../../assets/variables/colors.scss';

/* width */
.langs::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.langs::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.langs::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.languagesp {
    margin-top: 30px;

    .save-btn {
        width: 100%;
        background-color: #522583;
        border-radius: 9px;
        height: 47px;
        color: #ffffff;
        margin-top: 40px;

        &:hover {
            color: #ffffff;
            background-color: #522583;
        }
    }

    .lang-side {
        width: 100%;

        &.prof-side {
            width: 200px;
            margin-left: 30px;
        }

        &.trash-side {
            width: 50px;

            .trash-icon {
                display: block;
                padding: 10px;
                margin-top: 25px;
                cursor: pointer;
            }
        }

        .label {
            font-size: 14px;
            color: $black;
            font-weight: 400;
            margin-bottom: 10px;
            display: block;
        }
    }

    .lang {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .langs {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}