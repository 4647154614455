@import '../../../assets/variables/colors.scss';

.steps {
    display: flex;
    align-items: center;
    justify-content: center;

    &__step {
        display: flex;
        align-items: center;

        &-tail {
            width: 22px;
            height: 2px;
            background-color: #EEE;
            margin: 0 2px;

            &.active {
                background-color: $purple;
            }

            &.passed {
                background-color: $passed !important;
            }
        }

        &-step {
            width: 23px;
            height: 23px;
            background-color: #EEE;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
                background-color: $purple !important;
            }

            &.passed {
                background-color: $passed !important;
            }
        }

        &:last-child {
            .steps__step-tail {
                &:last-child {
                    display: none;
                }
            }
        }
    }
}