@import '../../../../../assets/variables/colors.scss';

.languagesp {
    margin-top: 30px;

    .error {
        border: 1px solid $warning;
    }

    .save-btn {
        width: 100%;
        background-color: #522583;
        border-radius: 9px;
        height: 47px;
        color: #ffffff;
        margin-top: 40px;

        &:hover {
            color: #ffffff;
            background-color: #522583;
        }
    }

    .lang-side {
        width: 100%;

        .err-msg {
            color: $warning;
            font-size: 12px;
        }

        &.prof-side {
            width: 200px;
            margin-left: 30px;

            &.edit-lang-prof {
                width: 100px !important;
            }
        }

        &.trash-side {
            width: 50px;

            .trash-icon {
                display: block;
                padding: 10px;
                margin-top: 25px;
                cursor: pointer;
            }
        }

        .label {
            font-size: 14px;
            color: $black;
            font-weight: 400;
            margin-bottom: 10px;
            display: block;

            &.err {
                color: $warning;
            }
        }
    }

    .lang {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}