@import '../../../assets/variables/colors.scss';

.teaser {
  max-height: 88vh;
  overflow-y: auto;
  padding: 10px 20px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
  }

  .teaser-block {
    border-radius: 13px;
    background: $white;
    box-shadow: 0px 1px 103px 0px rgba(141, 156, 174, 0.25);
    display: flex;
    flex-wrap: wrap;
  }

  .about-item {
    display: block;
    margin: 10px 0;

    &.sec {
      margin-top: 0 !important;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    margin-top: 56px;

    &__profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 290px;
      height: 320px;
      padding: 20px;
      margin-right: 20px;

      &-avatar {
        border-radius: 50%;
      }

      &-role {
        font-weight: 500;
        font-size: 20px;
        color: $black;
      }

      &-talent {
        color: $light-blue;
        font-size: 14px;
        font-weight: 500;
      }

      &-about {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin: 0 !important;
      }
    }

    &__second {
      padding:20px;
      width: 100%;
      min-height: 320px;
      height: fit-content;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      border-radius: 13px;
      background: $white;
      box-shadow: 0px 1px 103px 0px rgba(141, 156, 174, 0.25);

      &__cards {
        max-height: min-content;
        display: flex;
        align-items: center;
        gap: 10px;
        &__description {
          display: flex;
          flex-direction: column;
        }

        &__names {
          color: #2a2a2a;
          font-size: 16px;
          font-weight: bold;
        }
        &__cont-title {
          color: #2a2a2a;
        }
      }

      &-img {
        width: 30px;
        height: 30px;
        position: relative;
        border-radius: 50%;
        background-color: $background;
      }

      &__cont {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        &-title {
          font-weight: 500;
          font-size: 16px;
          color: $black;
          display: block;
          margin-bottom: 5px;
        }

        &-desc {
          color: $black;
          opacity: 0.8;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  .bg_styles {
    border-radius: 13px;
    background: #ffffff;
    box-shadow: 0px 1px 103px 0px rgba(141, 156, 174, 0.25);
  }

  &__bottom {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 50%;
      background-color: $white;
      border-radius: 13px;
      margin-right: 25px;
    }

    &-right {
      background-color: $white;
      width: 50%;
      border-radius: 13px;
    }
  }
  .btn_wrapper {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    .cancel_request {
      background-color: #e55353;
      padding: 14px 54px;
      color: #ffffff;
      border-radius: 10px;
      text-align: center;
      align-items: center;
    }
  }
}

@media screen and (max-height: 1200px) {
  .teaser {
    max-height: 70vh;
  }
}
