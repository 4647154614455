@import '../../assets/variables/colors.scss';
@import '../../assets/variables/mixins.scss';

.login {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__title {
        color: $black;
    }

    &__linked {
        height: 47px !important;
        background-color: $blue !important;
        color: $white !important;
        width: 406px !important;
        border-radius: 9px !important;
        margin-bottom: 20px !important;
        @include mobile {
            width: 100% !important;
        }
        &.fb {
            background-color: $sky-blue !important;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 10px;
                display: block;
            }
        }

        &.in {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 10px;
                display: block;
            }
        }

        .btn-icon {
            margin-right: 10px;
        }

        &:hover {
            background-color: $blue;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 28px;

        &-no {
            color: $black;
            font-weight: 600;
            font-size: 16px;
        }

        &-up {
            color: $purple;
            font-size: 16px;
            font-weight: 500;
            margin-top: 20px;
        }

    }

    form {
        width: 406px;
        margin-top: 20px;
        @include mobile {
            width: 100%;
        }
        .field {
            width: 100%;
            height: 40px;
            border: 1px solid $border;
            background-color: $body;
            border-radius: 3px;
            margin-bottom: 20px;

            input {
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                text-indent: 15px;
                background-color: transparent;
                color: $black;
            }
        }

        .forgot {
            color: $purple;
            font-weight: 400;
            margin-top: 8px;
            display: block;
            width: max-content;
            margin-left: auto;
            font-size: 16px;
        }

        .login-btn {
            width: 100%;
            border-radius: 9px;
            height: 47px;
            color: $white;
            background-color: $purple;
            margin-top: 33px;
        }
    }
    .or{
        margin-bottom: 10px;
        color: #2a2a2a;
    }
}