@import '../../assets/variables/colors.scss';

.congratsBLock {
  background-color: $white;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: auto;

  &-title {
    font-size: 40px;
    color: $black;
    margin-bottom: 13px;
    font-weight: 500;
  }

  &-desc {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    color: $black;
    width: 466px;
    line-height: 23px;
  }

  &-continue {
    width: 202px;
    height: 47px;
    border-radius: 9px !important;
    background-color: $purple !important;
    color: $white !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 25px !important;

    &:hover {
      background-color: $white !important;
      color: $purple !important;
      border: 1px solid $purple !important;
    }
  }
}
