@import '../../../../assets/variables/colors.scss';

.teaser {
  max-height: 88vh;
  overflow-y: auto;
  padding: 10px 20px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
  }

  .teaser-block {
    border-radius: 13px;
    background: $white;
    box-shadow: 0px 1px 103px 0px rgba(141, 156, 174, 0.25);
  }

  .about-item {
    display: block;
    margin: 10px 0;

    &.sec {
      margin-top: 0 !important;
    }
  }

  .industry-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__top {
    display: flex;
    align-items: center;
    margin-top: 56px;

    &__profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 290px;
      height: 320px;
      padding: 20px;
      margin-right: 20px;

      &-avatar {
        border-radius: 50%;
      }

      &-role {
        font-weight: 500;
        font-size: 20px;
        color: $black;
      }

      &-talent {
        color: $light-blue;
        font-size: 14px;
        font-weight: 500;
      }

      &-about {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin: 0 !important;
      }
    }

    &__industry {
      padding: 40px 20px;
      width: 100%;
      height: 320px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      &__item {
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 20px;

        &:nth-child(1) {
          grid-area: 1 / 1 / 2 / 2;
        }

        &:nth-child(2) {
          grid-area: 1 / 2 / 2 / 3;
        }

        &:nth-child(3) {
          grid-area: 1 / 3 / 2 / 4;
        }

        &:nth-child(4) {
          grid-area: 2 / 1 / 3 / 2;
        }

        &:nth-child(5) {
          grid-area: 2 / 2 / 3 / 3;
        }

        &:nth-child(6) {
          grid-area: 3 / 1 / 4 / 2;
        }

        &:nth-child(7) {
          grid-area: 3 / 2 / 4 / 3;
        }

        &:nth-child(8) {
          grid-area: 4 / 1 / 5 / 2;
        }

        &:nth-child(9) {
          grid-area: 4 / 2 / 5 / 3;
        }

        &:nth-child(10) {
          grid-area: 2 / 3 / 3 / 4;
        }

        &:nth-child(11) {
          grid-area: 3 / 3 / 4 / 4;
        }

        &-img {
          width: 30px;
          height: 30px;
          position: relative;
          border-radius: 50%;
          background-color: $background;
        }

        &__cont {
          display: flex;
          flex-direction: column;
          margin-left: 10px;

          &-title {
            font-weight: 500;
            font-size: 16px;
            color: $black;
            display: block;
            margin-bottom: 5px;
          }

          &-desc {
            color: $black;
            opacity: 0.8;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }

  &__bottom {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;

    &-left {
      width: 50%;
      background-color: $white;
      border-radius: 13px;
      margin-right: 25px;
    }

    &-right {
      background-color: $white;
      width: 50%;
      border-radius: 13px;
    }
  }
}

@media screen and (max-height: 1200px) {
  .teaser {
    max-height: 70vh;
  }
}
