@import '../../assets/variables/colors.scss';

.container {
    width: 100%;
    height: max-content;
    background-color: $white;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;

    .title {
      color: $black;
      font-size: 24px;
      font-weight: 600;
      padding: 30px
    }

    .info_section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 450px;
      padding-bottom: 30px;

      .info_item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left_side {
          color: $black;
          font-size: 18px;
          font-weight: 500;
        }

        .right_side {
          color: $black;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }