@import '../../assets/variables/colors.scss';

.down-arrow {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;

  &.active {
    transform: translate(-12px) rotate(180deg);
  }
}

.down-arrow:not(.future-option):not(.active) {
  transform: translate(-12px);
  margin-top: 5px;
}

.dropdown-cl {
  .notranslate {
    color: #676768;
    font-size: 14px;
  }

  #demo-simple-select {
    font-size: 14px;
  }

  fieldset {
    outline: none !important;
  }

  .dashboard__left-dropdown {
    background-color: $body;
    border: 2px solid $border;
  }
}

.disabled-placeholder {
  

  &.open {
   display: none !important;
  }
}

.clear-icon {
  transform: translate(-15px) translateY(4px);
  color: #676768;
  opacity: 0.5;
  cursor: pointer;
}

.error-message {
  color: $warning;
  font-size: 12px;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(0, 16px);

  &.active {
    visibility: visible;
  }
}

.dropdown-search {
  width: -webkit-fill-available !important;
}

.selection-dd {
  width: 100%;
  position: relative;
}

.dropdown-cl {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  &.err {
    .dropdown {
      border: 1px solid $warning !important;
    }
  }

  .notranslate {
    opacity: 0.5;
  }
}

.paper-dropdown {
  max-height: 200px !important;
}

.paper-dropdown::-webkit-scrollbar {
  width: 10px;
}

.paper-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px $gray;
  border-radius: 10px;
}

/* Handle */
.paper-dropdown::-webkit-scrollbar-thumb {
  background: $purple !important;
  border-radius: 10px;
}
