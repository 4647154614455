@import '../../assets/variables/colors.scss';

.match-filter {
  width: 100%;
  max-width: 425px;
  background-color: $white;
  padding: 20px 25px 35px;
  font-family: Rubik;
  font-style: normal;
  line-height: normal;
  color: $black;
  border-radius: 1px;
  height: fit-content;

  .form {
    .input-container {
      margin-bottom: 30px;

      input::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: $storm-gray;
        opacity: 1;
      }

      .dropdown {
        .notranslate {
          color: $storm-gray;
          font-family: Rubik;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .label {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
        display: inline-block;
      }

      .checkbox-label {
        font-size: 16px;
        font-weight: 500;
      }

      .input {
        width: 100%;
        height: 40px;
        outline: none;
        padding: 10px 16px;
      }

      .languages-container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 8px;
        flex-wrap: wrap;

        .language {
          display: flex;
          background-color: $body;
          border-radius: 2px;
          padding: 6px;
          align-items: center;
          gap: 7px;

          .language-name {
            font-size: 12px;
            font-weight: 400;
            color: $light-gray-100;
          }

          .remove {
            cursor: pointer;
          }
        }
      }
    }

    .submit-button {
      width: 100%;
      text-align: center;
      max-width: 382px;
      padding: 10px 22px;
      color: $white;
      background-color: $purple;
      font-size: 16px;
      font-weight: 500;
      border-radius: 9px;
    }

    .reset {
      width: fit-content;
      cursor: pointer;
      color: $purple;
      font-size: 16px;
      font-weight: 500;
      margin: 30px auto 0;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
}
