@import '../../../assets/variables/mixins.scss';
.overview-popup {
    padding: 30px 50px;
    max-width: fit-content;
    width: 100%;

    .popup__desc {
        width: 501px;
        line-height: 21px;
    }
}

.about__right__top__left-desc {
    width: 260px;
    line-height: 20px;
    @include tablet {
        width: 100%;
    }
}

.btn {
    position: relative;
    @include tablet {
        width: 100%;
    }
    .img-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(25px, -16px);
    }
}