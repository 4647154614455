@import '../../../assets//variables/colors.scss';

.filters {
  max-width: 422px;
  width: 100%;
  height: 100%;
  color: $black;
  border-radius: 1px;
  background: $white;
  padding: 25px 20px 26px 20px;

  &>h1 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  &_dropdown {
    margin-top: 10px;
    color: $storm-gray;
    max-width: 382px;
    width: 100%;
    max-height: 40px;
    height: 100%;
  }

  &>section>p {
    margin-top: 25px;
    width: inherit;
  }

  &_checkbox {
    svg {
      color: $purple;
    }

    &>h1 {
      color: $black;
      font-size: 16px;
      font-weight: 600;
      margin: 25px 0 5px 0;
    }

    input {
      color: $storm-gray;
    }
  }

  .filters_submit {
    display: flex;
    width: 100%;
    padding: 14px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 9px;
    background: $purple;
    color: $white;
    margin-top: 20px;
  }

  .filters_reset {
    color: $purple !important;
    width: -webkit-fill-available;
    background: unset !important;
    font-size: 16px !important;
    font-weight: 500 !important;

    span {
      margin: 0 auto;
    }
  }

  &_buttons {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize !important;
  }

  &_reset_button {
    margin-top: 26px;
  }
}