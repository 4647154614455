@import '../../../../assets/variables/colors.scss';
@import '../../../../assets/variables/mixins.scss';

/* width */
.medias_existing::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.medias_existing::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.medias_existing::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.medias {
    &_existing {
        max-height: 300px;
        overflow-y: auto;
        padding-right: 15px;

        &-trash {
            padding: 4px;
            margin-left: 10px;
            margin-top: 37px;
            cursor: pointer;

            @include mobile {
                margin: 0;
            }
        }

        &__media {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 25px;

            @include mobile {
                flex-direction: column-reverse;
                align-items: flex-start;
                border-bottom: 1px solid $border;
                padding-bottom: 30px;

                &:last-child {
                    border: 0;
                }
            }

            &__left {
                display: flex;
                flex-direction: column;

                @include mobile {
                    margin: 0;
                    width: 100%;
                }

                &-label {
                    font-weight: 400;
                    font-size: 14px;
                    color: $black;
                    display: block;
                    margin-bottom: 5px;
                }

                &-social {
                    width: 331px;
                    height: 40px;
                    border: 1px solid $border;
                    background-color: $body;
                    border-radius: 3px;
                    padding: 12px 16px;
                    color: $storm-gray;
                    font-size: 14px;
                    margin-right: 44px;

                    @include mobile {
                        width: 100%;
                    }
                }
            }

            &__middle {
                display: flex;
                flex-direction: column;
                width: 100%;

                @include mobile {
                    margin: 15px 0;
                }

                &-label {
                    font-weight: 400;
                    font-size: 14px;
                    color: $black;
                    display: block;
                    margin-bottom: 5px;
                }

                .level {
                    @include mobile {
                        margin: 5px 0;
                    }

                    .dots {
                        justify-content: space-between;
                        width: 112px;

                        .dot {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }
    }

    &__add {
        margin-top: 20px;

        &-title {
            font-weight: 500;
        }

        .cont {
            display: flex;
            margin-top: 25px;
            justify-content: space-between;

            @include mobile {
                flex-direction: column;
                align-items: flex-start;
            }

            .left {
                .social_dropdown {
                    width: 331px;
                    background-color: $body;
                    border: none;
                    height: 40px;

                    fieldset {
                        border: 1px solid $border;
                    }
                }
            }

            .right {
                width: 197px;

                @include mobile {
                    margin-top: 20px;
                }

                .level {
                    margin-top: 25px;
                }

                .dots {
                    justify-content: space-between;
                    width: 112px;

                    .dot {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }

    .save-btn {
        width: 100%;
        height: 47px;
        border-radius: 9px;
        background-color: $purple;
        color: $white;
        margin-top: 30px;
        background-color: $purple !important;
        color: $white !important;

        &:hover {
            background-color: $purple !important;
            color: $white !important;
        }
    }
}