@import '../../assets/variables/colors.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-left: 30px;
  padding-right: 30px;

  .title {
    color: $black;
    font-size: 24px;
    font-weight: 600;
  }

  .card {
    cursor: pointer;
  }
}

.MuiTableRow-root {
  display: flex !important;
  width: 100% !important;
}

.MuiTableCell-root {
  margin: 2px;
  border-radius: 10px;
  padding: 10px !important;
  padding-left: 15px !important;
}

.MuiTableCell-root {
  width: calc(28% - 20px);
  height: 44px;
  .card {
    align-items: center;
  }
}
.css-1iyrxsq-MuiPaper-root-MuiTableContainer-root{
  overflow: initial !important;
}

.MuiTableCell-root:nth-child(1) {
  width: calc(19% - 20px);
}

.MuiTableCell-root:nth-child(2) {
  width: calc(25% - 20px);
}

.MuiTableCell-root:nth-child(5) {
  width: 80px;
}
