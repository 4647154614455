@import '../../assets/variables/colors.scss';

.terms_context {
  margin-top: 40px;

  &_title {
    color: $purple;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.25px;
    font-family: Work Sans !important;
  }

  &_about,
  &_terms {
    margin-top: 10px;
    color: $black;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-align: justify;

    p,
    span {
      color: $black;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }

    div {
      margin-top: 40px;
      margin-bottom: 26px;
    }
  }

  &_terms {
    p {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }

  &_about {
    p {
      margin-bottom: 15px;
    }
  }
}