.tab__photos__content {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 10px;

  .tab__photos__content-photo {
    margin-bottom: 15px;
    width: 256px;
    height: 156px;
    border-radius: 15px;
    object-fit: cover;

    &:hover {
      opacity: 0.5;
      cursor: zoom-in;
    }

    &:nth-child(1) {
      width: 146px;
      height: 136px;
    }
    &:nth-child(2) {
      width: 307px;
      height: 136px;
    }
    &:nth-child(3) {
      width: 307px;
      height: 136px;
    }
    &:nth-child(4) {
      width: 146px;
      height: 136px;
    }
    &:nth-child(5) {
      width: 307px;
      height: 136px;
    }
  }
}
