@import '../../../../assets/variables/colors.scss';

.education {
    .blocks {
        margin-top: 30px;
        height: 400px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 10px;
          }
          
        &::-webkit-scrollbar-track {
            border-radius: 10px;
          }
          
          /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $purple !important;
            border-radius: 10px;
          }

        &__block {
            border-radius: 6px;
            background: $white;
            box-shadow: 0px 1px 12.1px 0px $shadow;
            padding: 30px;
            margin-bottom: 20px;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &-title {
                    color: $black;
                    font-size: 18px;
                    font-weight: 600;
                    display: block;
                    margin-bottom: 8px;
                    margin-right: 20px;
                }

                &-years {
                    opacity: 0.8;
                    color: $black;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            &__cont {
                &-sub {
                    opacity: 0.8;
                    color: $black;
                    font-size: 14px;
                }

                &__actions {
                    display: flex;
                    align-items: center;
                    margin-top: 17px;

                    &-action {
                        display: flex;
                        align-items: center;
                        margin-right: 20px;

                        &__img {
                            width: 26px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            background-color: $background;
                            margin-right: 10px;
                        }

                        &__title {
                            font-weight: 400;
                            font-size: 12px;
                            color: $black;
                        }
                    }
                }
            }
        }
    }

    .main-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;

        .btn {
            width: 187px;
            height: 47px;
            border-radius: 9px;
            font-size: 16px;
            font-weight: 500;

            &.back {
                background-color: $storm-white;
                color: $purple;
            }

            &.next {
                background-color: $purple;
                color: $white;
            }
        }
    }


    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        .button {
            width: 187px;
            height: 47px;
            border-radius: 9px;

            &.back {
                background-color: $storm-white;
                color: $purple;
            }

            &.next {
                background-color: $purple;
                color: $white;
            }
        }
    }
}