@import '../../../assets/variables/colors.scss';

.item {
    display: flex;
    align-items: center;

    &__icon {
        position: relative;
        padding: 13px;
        border-radius: 50%;
        background-color: $light-rose;
        margin-right: 6px;

        &-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
        }

    }

    &__title {
        color: $black;
        font-size: 12px;
    }
}