@import '../../assets/variables/colors.scss';

/* width */
.scrollable::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.scrollable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.scrollable::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.block {
    padding: 20px;
    border-radius: 6px;
    background-color: $white;


    &__content {
        &.scrollable {
            max-height: 200px;
            min-height: 100px;
            overflow-y: auto;
        }

    }

    &__header {
        display: flex;
        justify-content: space-between;

        &-title {
            font-size: 20px;
            color: $black;
            font-weight: 600;
        }

        .buttons {
            display: flex;

            &.btns {
                margin-top: 0 !important;
            }

            .edit {
                margin-left: 15px;
            }
        }
    }
}