@import '../../assets/variables/colors.scss';

.company {
  padding: 0 50px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      border-radius: 6px;
      background-color: $white;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 51px;

      &__image {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        flex-direction: column;
        position: relative;
        width: 243px;
        height: 145px &-edit {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(23px, -31px);
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        margin-left: 50px;

        &-title {
          color: $black;
          font-size: 24px;
          font-weight: 600;
          display: block;
          margin-bottom: 10px;
        }

        &-subtitle {
          font-size: 18px;
          font-weight: 400;
          display: block;
          margin-bottom: 8px;
          color: $black;
        }

        &-credit {
          color: $black;
          opacity: 0.5;
          font-size: 14px;
          font-weight: 400;
        }

        &__buttons {
          display: flex;
          align-items: center;
          margin-top: 30px;

          &-edit {
            margin-right: 13px !important;
            background-color: $body !important;
            color: $purple !important;
            width: 130px;
            height: 47px;
            transition: all 0.5s;

            &:hover {
              background-color: $purple !important;
              color: $body !important;
            }
          }

          &-upgrade {
            background-color: $purple !important;
            color: $white !important;
            width: 130px;
            height: 47px;
            transition: all 0.5s;

            &:hover {
              background-color: $background !important;
              color: $purple !important;
            }
          }
        }

        .button {
          border-radius: 9px;
          width: 130px;
          height: 47px;
        }
      }
    }

    &__right {
      width: 40%;
      background-color: $white;
      border-radius: 6px;
      margin-left: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    height: 46px;
    border-radius: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #2a2a2a;

    &__item {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;
      margin-right: 10px;
      cursor: pointer;

      &.active {
        .line {
          width: 100%;
        }
      }

      &:hover {
        .line {
          width: 100%;
        }
      }

      &-line {
        transition: all 0.5s;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 0%;
        background-color: $purple;
        transform: translateY(15px);
      }
    }
  }
}
