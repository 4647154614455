@import '../../../assets/variables/colors.scss';

.s-block {
    color: $purple;
    border-radius: 28px;
    width: max-content;
    height: 37px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(82, 37, 131, 0.1);
    padding: 20px;
}