@import '../../assets/variables/colors.scss';

.matchblock {
    border-radius: 5px;
    width: 251px;
    height: 130px;
    background-color: $white;
    padding: 21px 29px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 1px 103px 0px rgba(141, 156, 174, 0.25);

    &-name {
        color: $black;
        opacity: 0.8;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            font-weight: 600;
            color: $black;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; 
            flex: 1 1 auto; 
            line-height: 1.2;
        }

        &-date {
            color: $black;
            font-weight: 300;
            font-size: 12px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-action {
            width: 36px;
            height: 36px;
            border-radius: 50%;

            img {
                filter: brightness(0) invert(1);
            }

            &:nth-child(1) {
                background-color: $action-purple;
            }

            &:nth-child(2) {
                background-color: $action-rose;
            }

            &:nth-child(3) {
                background-color: $action-blue;
            }

            &:nth-child(4) {
                background-color: $action-green;
            }
        }
    }
}