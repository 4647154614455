@import '../../../../assets/variables/colors.scss';
@import '../../../../assets/variables/mixins.scss';

/* width */
.extras::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.extras::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px $gray;
  border-radius: 10px;
}

/* Handle */
.extras::-webkit-scrollbar-thumb {
  background: $purple;
  border-radius: 10px;
}

.extra {
  form {
    @include mobile {
      height: 65vh;
      overflow-y: scroll;
    }
  }
  .extras {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 15px;
  }

  &__all {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    margin-top: 30px;

    &__left {
      width: 80%;

      &-title {
        color: $black;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        justify-content: space-between;

        &-year {
          color: $black;
          opacity: 0.8;
          font-size: 14px;
        }
      }

      &-type {
        font-size: 12px;
        color: $black;
        opacity: 0.8;
      }
    }

    &__right {
      display: flex;
      align-items: center;

      &-action {
        cursor: pointer;
        padding: 5px;
        margin-right: 15px;
        margin-bottom: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .extra__content__start__left {
    @include tabletS {
      width: 100%;
    }
    @include mobile {
      width: 100%;
      margin-top: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .add-experience {
    display: flex;
    align-items: center;
    margin: 25px 0;
    width: max-content;
    cursor: pointer;

    .action-add {
      width: 36px;
      height: 36px;
    }

    .add-experience-title {
      font-size: 14px;
      font-weight: 400;
      color: $black;
      display: block;
      margin-left: 10px;
    }
  }

  .drops {
    display: flex;
    align-items: center;
  }

  .down-extr {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    @include tabletS {
      width: 100%;
      .down-extr {
        display: flex;
        flex-direction: column;
        .closer-dropdown {
          width: 100%;
          .dropdowns {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
      }
    }

    @include mobile {
      flex-direction: column;
    }
  }

  .save-btn {
    background-color: $purple;
    border-radius: 9px;
    width: 100%;
    height: 47px;
    color: $white;
    margin-top: 30px;

    &:hover {
      background-color: $purple !important;
      color: $white !important;
    }
  }

  .activity_input {
    height: 40px;
    width: 100%;
    position: relative;

    .fmessage {
      font-size: 12px;
      color: $warning;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translate(12%, 132%);
      background-color: $body;
    }

    input {
      width: 100%;
      height: 100%;
      outline: none;
      border: 1px solid $border;
      background-color: $body;
      color: $storm-gray;
      font-size: 14px;
      font-weight: 400;
      border-radius: 3px;
      text-indent: 20px;
    }

    &.descs {
      height: 56px;
    }

    .error {
      border: 1px solid $warning;
    }
  }

  .label {
    font-size: 14px;
    color: $black;
    display: block;
    margin-bottom: 5px;
  }

  .dropdowns {
    height: 40px;
    border: 1px solid $border;
    background-color: $body;
    font-size: 14px;
    @include tabletS {
      width: 100% !important;
      max-width: 100% !important;
    }

    &.day {
      max-width: 100px;
    }

    fieldset {
      border: none;
    }

    &.month {
      max-width: 121px;
    }

    &.year {
      max-width: 110px;
    }
  }

  &__content {
    &__activity {
      margin-bottom: 25px;
      display: flex;
      align-items: center;

      &-trash {
        display: flex;
        width: 40px;
        height: 40px;
        cursor: pointer;
        justify-content: flex-end;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
      }

      &__field {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.website {
          margin-left: 27px;
        }
      }
    }

    &__start {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__desc {
      margin-top: 25px;
    }

    &__new {
      margin-top: 25px;

      &-title {
        font-size: 16px;
        font-weight: 500;
        color: $black;
        display: block;
        margin-bottom: 15px;
      }
    }
  }
}
