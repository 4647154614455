@import '../../assets/variables/colors.scss';
.card_container {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &_massages {
    text-align: center;
    border-bottom: 1px solid gray;
    padding: 5px;
    margin-bottom: 10px;
  }
}
.card {
  align-items: start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 200px;

  .profile {
    margin: 0;
  }

  .profile__dot {
    width: 15px;
    height: 15px;
    transform: translateX(-3px);
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    &_btn {
      margin-top: 10px;
      display: flex;
      gap: 1rem;
      width: 100%;
      border-top: 1px solid gray;
      padding: 5px;
      &_read {
        width: 100%;
        background-color: $white;
        color: $purple;
        font-weight: 400;
        border: 1px solid $purple;
        border-radius: 9px;
        padding: 10px;
      }
      &_show {
        width: 100%;
        background-color: $purple;
        color: $white;
        font-weight: 400;
        border: 1px solid $purple;
        border-radius: 9px;
        padding: 10px;
      }
    }
    &-sender {
      color: $black;
      font-weight: 600;
      font-size: 16px;
    }

    &-message {
      color: $black;
      font-weight: 400;
      font-size: 16px;
      width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: keep-all;
    }

    &-date {
      align-self: flex-end;
      color: rgb(28, 157, 215);
      font-size: 14px;
      font-family: Rubik;
      text-align: right;
    }
  }
  .no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
