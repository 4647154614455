@import '../../../../assets/variables/colors.scss';

.full {
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .top {
    display: flex;
    align-items: flex-start;
    width: 600px;

    .prefs {
      width: 400px;
      padding: 20px 30px;
    }
  }

  .sk-block-side {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .blocks {
      margin-right: 10px;
    }
  }

  .skills {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .blocks {
      .s-block {
        background-color: transparent;
        color: $black;
        padding: 0;
        width: max-content;
        display: inline-block;
      }
    }
  }

  .langs {
    padding: 20px 30px;
    width: 300px;

    .languages {
      width: 200px;

      .level {
        width: 100px;
      }

      .lang {
        display: flex;
        align-items: center;

        .name {
          font-size: 15px;
          color: $purple;
        }
      }
    }

    .lang-and-flag {
      display: flex;
      align-items: center;

      img {
        margin-left: 20px;
      }

      .names {
        width: 100px;
      }
    }
  }

  .edus {
    margin-top: 20px;
  }

  .school-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .value {
      display: inline-block;
      margin-left: 5px;
    }

    .items {
      font-weight: bold;
      color: $purple;
    }
  }
}

.wexper {
  border-bottom: 1px solid $black;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
    border: none;
    padding-bottom: 0;
  }
}
