@import '../../../assets/variables/colors.scss';

.pagination {
    display: flex;
    align-items: center;

    .arrow {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.prev {
            transform: translate(-50%, -50%) rotate(-90deg);
        }

        &.next {
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }

    &-item {
        cursor: pointer;
        position: relative;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 1px solid $border-gray;
        display: block;
        margin-right: 20px;

        &:last-child {
            margin: 0;
        }

        &.page-item {
            background-color: $storm-white;
            border-color: $purple;
            color: $purple;
            font-weight: 600;
        }
    }
}