@import '../../../../assets/variables/colors.scss';

.matchPopup {
    margin-top: 25px;

    .label {
        font-size: 14px;
        font-weight: 400;
        display: block;
        margin-bottom: 5px;
    }

    .save {
        background-color: $purple;
        width: 100%;
        color: $white;
        border-radius: 9px;

        &.invalid {
            opacity: 0.8;
            pointer-events: none;
        }

        &:hover {
            background-color: $purple;
            color: $white;
        }
    }

    .fields {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .field {
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;
            width: 276px;

            .examp {
                font-size: 12px;
                opacity: 0.8;
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translate(10px, 11px);
                color: $black;
            }

            .input {
                width: 276px;
                height: 40px;
                border-radius: 3px;
                border: 1px solid $border;
                background-color: $body;
                text-indent: 15px;
                outline: none;
            }
        }
    }
}