@import '../../../../assets/variables/colors.scss';

.njob {
    max-height: 600px;
    overflow: auto;
    form {
        margin-top: 25px;
    }

    .industry-selected {
        height: 26px;
        background-color: $body;
        color: $gray;
        font-size: 12px;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
        padding: 0 5px;
        margin-top: 10px;

        .remove {
            cursor: pointer;
            margin-left: 8px;
        }
    }

    .field {
        .error {
            border: 1px solid $warning;
        }

        .error-msg {
            color: $warning;
            font-size: 12px;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(10px, 15px);
        }

        &.many {
            width: 50%;

            .dropdown-cl {
                margin-left: 0;
            }

            &.autocomplete {
                margin-left: 10px;
            }
        }

        .label {
            color: $black;
            font-size: 14px;
            font-weight: 400;
            display: block;
            margin-bottom: 5px;
        }

        .input {
            width: 100%;
            background-color: $body;
            border: 1px solid $border;
            height: 40px;
            outline: none;
            text-indent: 10px;
        }
    }

    .fields {
        display: flex;
        justify-content: space-between;
    }

    .save {
        width: 100%;
        background-color: $purple;
        border-radius: 9px;
        color: $white ;
        margin: 10px 0;

        &:disabled {
            opacity: 0.5;
        }
        &:hover {
            background-color: $white;
            border: 1px solid $purple;
            color: $purple;
          }
    }
    .checkboxes{
        display: flex;
        justify-content: flex-start ;
    }
}
