@import '../../../assets/variables/colors.scss';

.assessment-year-label-wrapper {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 14px;

  .assessment-year-label-color {
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}
