@import '../../../assets/variables/colors.scss';

.bigModal {
  min-width: 1500px;
  padding: 40px;
  background-color: $white;
  border-radius: 32px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  max-height: 100vh;
  overflow: auto;
  transform: translate(-50%, -50%);
}

.fromCareer {
  min-width: 657px;
  padding: 40px;
  background-color: $white;
  border-radius: 32px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

  .activation {
    width: 841px;
    padding: 40px 135px;
    background-color: $white;
    border-radius: 32px;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.activation-edu {
      padding: 40px 86px;
      width: 1091px;
    }

    &.edu-experience {
      padding: 13px 54px 42px !important;
      width: 1001px !important;
    }

    &__body {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: $white;
      border-radius: 32px;

      &-content {
        margin-top: 15px;
        width: 100%;
      }

      &-logo {
        margin-bottom: 11px;
      }

      &-title {
        color: $black;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 15px;
        display: block;
      }

      &-sub {
        font-size: 16px;
        color: $black;
        text-align: center;
        font-weight: 400;
      }
    }
  }
