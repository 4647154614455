@import '../../../../assets/variables/colors.scss';

/* width */
.videos::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
.videos::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.videos::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.youtube {
    .videos {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 30px;

        .video-preview-img {
            width: 279px;
            height: 163px;
        }

        .video {
            width: 280px;
            height: 163px;
            margin-right: 35px;
            position: relative;

            .removeVideo {
                position: absolute;
                right: 0;
                top: 0;
                background-color: $rose;
                transform: translate(-20px, 16px);

                .edit-img {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    .search {
        position: relative;
        margin-top: 30px;

        .search-icon {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translate(12px, -13px);
        }

        .label {
            font-size: 14px;
            font-weight: 400;
            color: $black;
            display: block;
            margin-bottom: 5px;
        }

        .input {
            height: 40px;
            width: 100%;
            background-color: $body;
            border: none;
            border-radius: 6px;
            outline: none;
            text-indent: 40px;
            color: black;
        }

        .error {
            border: 1px solid $warning !important;
        }
    }

    .save {
        width: 100%;
        height: 47px;
        background-color: $purple;
        font-weight: 500;
        color: $white;
        border-radius: 9px;
        margin-top: 30px;
    }

    .error-wrapper {
        position: relative;
    }
}