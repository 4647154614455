@import '../../../assets/variables/colors.scss';

.chip {
    &-selected {
        height: 26px;
        background-color: $body;
        color: $gray;
        font-size: 12px;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
        padding: 0 5px;
        margin-top: 10px;

        .remove {
            cursor: pointer;
            margin-left: 8px;
        }
    }
}