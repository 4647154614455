@import '../../../assets/variables/colors.scss';

.certificate_body {
    display: flex;
    flex-direction: column;

    .tab__content__desc-text {
        display: block;
        margin-bottom: 15px;
    }
}

.tab__content__certification {
    margin-bottom: 15px;
    border-bottom: 1px solid $border;

    &:last-child {
        border: 0;
    }
}

.tab__content__desc-received {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.8;
    color: $black;
}