.container {
  gap: 0px;
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
  .fields {
    margin-top: 0px;
    gap: 10px;
    margin-right: 0px;
  }
  .next {
    height: 50px;
  }
  .imagePickerContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
  }
}
