@import '../../assets/variables/colors.scss';
@import '../../assets/variables/mixins.scss';

.fjobs {
    display: flex;
    flex-direction: column;
    max-width: 1520px;
    margin: 0 14%;
    @media (max-width: 768px) {
        margin: 0 auto;
    }
    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 30px;
    }
    .middle {
        display: flex;
    }

    .back_buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $purple;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        margin: 0 30px 20px;
    }

    .footers {
        margin-top: auto;
    }

    .filter-and-jobs {
        display: flex;
        position: relative;
        .filtering {
            width: 500px;
            @include tablet {

                transform: translateX(-227px);
                width: 0;
                &.show {
                    transform: translateX(0);
                    width: 500px;
                }
            
        }
        }
  
        .filter-icon {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            background-color: $body;
            position: relative;
        }
    }

    &__filter {
        width: 422px;
        background-color: $white;
        border-radius: 1px;
        margin-right: 20px;
        padding: 25px 20px;

        &-title {
            color: $black;
            font-size: 24px;
            font-weight: 600;
            display: block;
            text-align: center;
        }
    }

    &__matches {
        background-color: $white;
        border-radius: 2px;
        width: 100%;
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &__cards {
            width: 100%;
        }

        &-title {
            font-size: 24px;
            color: $black;
            font-weight: 600;
            margin-bottom: 35px;
            display: block;
            text-align: center;
        }

        &__card {
            margin-bottom: 15px;
        }
    }
}