@import '../../../assets/variables/colors.scss';

fieldset {
  border: none !important;
}
.scrollableInput {
  max-height: 350px !important;
}
.complete {
  position: relative;

  input {
    padding-right: 50px !important;
  }

  .error {
    border: 1px solid $warning;
    border-radius: 2px;
  }

  .close-autocom {
    cursor: pointer;
  }

  .location {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    transform: translate(-13px, -12px);
    cursor: pointer;

    &.rotate {
      transform: translate(-13px, -12px) rotate(180deg);
    }
  }

  .close-autocom {
    transform: translateX(-40px);
  }
}
