@import '../../assets/variables/colors.scss';

.starter {
  margin-top: 10px;

  &__text {
    color: $black;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    width: 598px;
    margin: auto;
    line-height: 21px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 95px;
    margin-bottom: 20px;

    .btn {
      width: 187px;
      height: 47px;
      border-radius: 9px;
      font-size: 16px;
      font-weight: 500;

      &.back {
        background-color: $storm-white;
        color: $purple;
      }

      &.next {
        background-color: $purple;
        color: $white;
      }
    }
  }
}

.activations {
  width: 841px;
  padding: 40px 135px;
  background-color: $white;
  border-radius: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
