@import '../../../assets/variables/colors.scss';

.field {
  .error {
    border: 1px solid $warning;
  }

  .error-msg {
    color: $warning;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(10px, 15px);
  }

  &.many {
    width: 50%;

    .dropdown-cl {
      margin-left: 0;
    }

    &.autocomplete {
      margin-left: 10px;
    }
  }

  .label {
    color: $black;
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
  }

  .input {
    color: #2a2a2a;
    width: 100%;
    background-color: $body;
    border: 1px solid $border;
    height: 40px;
    outline: none;
    text-indent: 10px;
  }
}

.fields {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-right: 10px;
}

.buttonContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;

  .button {
    width: 347px;
    height: 47px;
    border-radius: 9px;

    &.back {
      background-color: $storm-white;
      color: $purple;
    }

    &.next {
      background-color: $purple;
      color: $white;

      &.one-btn {
        width: 100%;
      }
    }
  }
}
