@import '../../assets/variables/colors.scss';

.details {
    background-color: $white;
    border-radius: 6px;
    padding: 35px;

    .back {
        cursor: pointer;
    }

    &-title {
        font-size: 24px;
        font-weight: 600;
        color: $black;
        text-align: center;
        margin-bottom: 35px;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 25px;

        &__left {
            &__items {
                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    &-span {
                        color: $black;
                        font-size: 18px;
                        font-weight: 400;

                        &.titles {
                            width: 200px;
                            margin-right: 40px;
                        }

                        width: 92;

                        &.total {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}