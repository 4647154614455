.image-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image_modal-content {
  max-width: 80vw;
  max-height: 80vh;
  border-radius: 8px;
  overflow: hidden;
  border-color: none;
  outline: none;
}

.image_modal-content img {
  width: 60vw;
  object-fit: contain;
}
