@import '../../assets/variables/colors.scss';

.jobdetails {
    padding: 0 30px;
    max-width: 1520px;
    margin: 0 auto;

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &__back {
            display: flex;
            align-items: center;
            cursor: pointer;

            &-overview {
                color: $purple;
                font-weight: 500;
                font-size: 16px;
                margin-left: 10px;
            }
        }

        &-share {
            background-color: $storm-white !important;
            border-radius: 9px !important;
            width: 170px !important;
            height: 47px !important;
            color: $purple !important;

            .btn-icon {
                margin-right: 10px;
                transform: translateY(-2px);
            }
        }
    }

    &__chart {
        display: flex;

        &-left {
            background-color: $white;
            width: 50%;
            height: 631px;
            margin-right: 25px;
            border-radius: 3px;
        }

        &-right {
            background-color: $white;
            width: 50%;
            height: 631px;
            border-radius: 3px;
            text-align: center;
            padding: 15px 20px ;
        }
    }

    &__about {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        &__sides {
            background-color: $white;
            border-radius: 3px;
            padding: 40px;
            height: 250px;

            &__left {
                display: flex;
                align-items: center;
                width: 100%;

                &-image {
                    border-radius: 50%;
                    margin-right: 17px;
                }

                &__content {
                    display: flex;
                    flex-direction: column;

                    &-title {
                        color: $black;
                        font-size: 24px;
                        font-weight: 500;
                    }

                    &__location {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;

                        &-icon {
                            display: block;
                            width: 26px;
                            height: 26px;
                            border-radius: 50%;
                            position: relative;
                            background-color: $background;

                            .location {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }

                        &-title {
                            font-size: 14px;
                            font-weight: 400;
                            color: $black;
                            margin-left: 9px;
                            display: block;
                            margin-top: 10px;

                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }

                    &-desc {
                        color: $black;
                        opacity: 0.8;
                        font-size: 14px;
                        margin-top: 10px;
                        line-height: 20px;
                    }
                }
            }

            &__right {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                &__item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;

                    &__image {
                        position: relative;
                        background-color: $background;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;

                        &-img {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    &__content {
                        display: flex;
                        flex-direction: column;
                        margin-left: 10px;

                        &-title {
                            color: $black;
                            font-weight: 500;
                            font-size: 16px;
                            display: block;
                            margin-bottom: 5px;
                        }

                        &-desc {
                            font-size: 14px;
                            color: $black;
                            opacity: 0.8;
                        }
                    }

                    &:nth-child(1) {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    &:nth-child(2) {
                        grid-area: 2 / 1 / 3 / 2;
                    }

                    &:nth-child(3) {
                        grid-area: 1 / 2 / 2 / 3;
                    }

                    &:nth-child(4) {
                        grid-area: 2 / 2 / 3 / 3;
                    }

                    &:nth-child(5) {
                        grid-area: 1 / 3 / 2 / 4;
                    }
                }
            }

            &.left {
                width: calc(50% - 15px);
                margin-right: 15px;
                height: 250px;
            }

      &.right {
        width: calc(50% - 15px);
      }
    }
  }
  &__btn {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2rem;
    padding: 30px 0 50px 0;
    &__rigth {
      min-width: 252px !important;
      height: 47px !important;
      background-color: $purple !important;
      color: $white !important;
      border-color: $purple !important;
      border-radius: 9px !important;
    }
  }
}
