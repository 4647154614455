@import '../../../assets/variables/colors.scss';
@import 'react-advanced-cropper/dist/style.css';

.av {
    .cropper {
        width: 236px;
        height: 236px;
        border-radius: 10px;
        margin: 30px auto auto;
    }

    .file {
        visibility: hidden;
    }

    &__content {
        display: flex;
        flex-direction: column;

        &-save {
            background-color: $purple !important;
            margin-top: 30px !important;
            color: $white !important;
            font-size: 16px !important;
            font-weight: 500 !important;
            border-radius: 9px !important;
            width: 100% !important;

        }

        &__upload {
            width: 503px;
            height: 222px;
            border-radius: 28px;
            border: 1px dashed rgba(82, 37, 131, 0.19);
            background: rgba(82, 37, 131, 0.07);
            margin: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin-top: 30px;

            &__top {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 23px;

                &-title {
                    font-weight: 600;
                    color: $black;
                    font-size: 14px;
                    display: block;
                    margin-top: 10px;
                    margin-bottom: 5px;
                }

                &-support {
                    opacity: 0.5;
                    font-size: 12px;
                    color: $black;
                }

                &-or {
                    font-weight: 600;
                    color: $black;
                    font-size: 14px;
                    display: block;
                    margin-top: 10px;
                }
            }

            &__bottom {
                text-align: center;

                &-browse {
                    color: $purple !important;
                    font-size: 16px !important;
                    font-weight: 500 !important;
                    width: 236px !important;
                    height: 47px !important;
                    border-radius: 9px !important;
                    background-color: $light-rose !important;
                }
            }
        }
    }
}