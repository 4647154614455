@import '../../../assets/variables/colors.scss';

.term {
    &__title {
        color: $black;
        font-size: 18px;
        display: block;
        font-weight: 700;
        margin-bottom: 10px;
    }

    &__desc {
        font-size: 16px;
        color: $black;
        font-weight: 400;
    }

}