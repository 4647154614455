@import '../../../../assets/variables/colors.scss';

.download-pdf {
  width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .anonym {
    margin-top: 30px;
    display: flex;
    align-items: center;

    label {
      margin-left: 10px;
    }

    .MuiCheckbox-sizeMedium {
      padding: 0 !important;
    }

    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
      color: $purple;
    }
  }

  .item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;

    .text {
      color: $purple;
      font-weight: 400;
      font-size: 16px;
    }

    .download {
      cursor: pointer;
    }
  }

  .hidden {
    overflow: 'hidden';
    height: 0;
    width: 0;
  }
}
