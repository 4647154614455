@import '../../assets/variables/colors.scss';

.matching {
  background-color: $white;
  border-radius: 6px;
  padding: 35px;

  .match-blocks {
    display: flex;
    align-items: center;

  
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;

    &-title {
      color: $black;
      font-size: 24px;
      font-weight: 600;
    }

    &-btn {
      background-color: $purple !important;
      color: $white !important;
      border-radius: 9px !important;
      font-size: 16px !important;
      width: 230px;
      font-weight: 500;
      color: $white;
      height: 47px;

      &:hover {
        background-color: $body !important;
        color: $purple !important;
      }
    }
  }
  .popup-add-new {
    max-width: 976px;
    padding: 40px;
    max-height: 1181px;
    margin: 20px 0;
    .popup__desc {
      max-width: 916px;
    }
  }
}
