@import '../../../../../assets/variables/colors.scss';

/* width */
.general::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.general::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.general::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.general {
    height: 280px;
    margin-top: 30px;
    overflow-y: auto;

    &__term {
        margin-bottom: 15px;
    }
}