@import '../../assets/variables/colors.scss';

.memberInfo {
    &__top {
        background-color: #FEFEFE;
        width: 98%;
        padding: 20px 20px 40px 20px;
        margin: auto;

        &-title {
            text-align: center;
            margin-bottom: 11px;
            color: $black;
            font-size: 24px;
            font-weight: 600;
            display: block;
            margin-bottom: 30px;
        }

        &__pricing {
            display: flex;
            align-items: center;
            justify-content: center;

            &-option {
                margin-right: 48px;
            }

            .option {
                box-shadow: 0px 6px 95.7px 0px rgb(232 235 236 / 70%)
            }

            .premium-btn {
                background-color: $white;
                border: 1px solid $white;
                width: 100%;
                height: 47px;
                border-radius: 9px;
                color: $light-blue;
                font-weight: 600;
                font-size: 16px;
                margin-top: 20px;
            }

            .pro-btn {
                background-color: $light-blue;
                border: 1px solid $light-blue;
                width: 100%;
                height: 47px;
                border-radius: 9px;
                color: $white;
                font-weight: 600;
                font-size: 16px;
                margin-top: 20px;
            }
        }
    }

    &__include {
        width: 98%;
        background-color: #FEFEFE;
        margin: 20px auto;
        padding: 20px;

        &-title {
            text-align: center;
            margin-bottom: 11px;
            color: $black;
            font-size: 24px;
            font-weight: 600;
            display: block;
            margin-bottom: 30px;
        }

        &__plans {
            display: grid;
            grid-template-columns: auto auto auto;
            align-items: center;
            justify-content: center;
            width: 78%;
            margin: auto;
            column-gap: 90px;

            &__items {
                display: flex;
                align-items: center;
                margin-bottom: 25px;
                max-width: 290px;
                margin-left: 39px;

                &-title {
                    font-weight: 600;
                    font-size: 16px;
                    display: block;
                    margin-left: 12px;
                }
            }
        }
    }

    &__accept {
        background-color: $white;
        margin-top: 20px;
        padding: 30px;
        position: relative;

        &-ssl {
            position: absolute;
            right: 0;
            bottom: 0;
            transform: translate(-40px, -23px);
        }

        &-title {
            font-size: 24px;
            color: $black;
            font-weight: 600;
            display: block;
            text-align: center;
        }

        &__methods {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 37px;
            gap: 55px;

            &-method {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                border-radius: 30px;
                background-color: $white;
                box-shadow: 0px 1.818px 29px 0px rgba(143, 151, 153, 0.20);
            }
        }
    }

    &__faq {
        background-color: $white;
        padding: 44px 15%;
        margin-top: 20px;
    }

}