@import '../../assets/variables/colors.scss';

.privacyPolicy {
  color: $black;

  &_content {
    max-width: 1412px;
    border-radius: 2px;
    background: $white;
    margin: 0 auto;
    padding-top: 30px;
    margin-bottom: 40px;

    &_privacybeleid {
      color: $black;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }
  }

  &_title {
    color: $black;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  &_texts {
    padding: 0 260px 47px 200px;
  }

  &_context {
    margin-top: 40px;

    &_title {
      color: $purple;
      font-size: 20px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.25px;
    }

    &_about {
      margin-top: 10px;
      color: $black;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }
}
