@import '../../../../assets/variables/colors.scss';
@import '../../../../assets/variables/mixins.scss';

/* width */
.descs::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.descs::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px $gray;
    border-radius: 10px;
}

/* Handle */
.descs::-webkit-scrollbar-thumb {
    background: $purple;
    border-radius: 10px;
}

.wexperience {
    .save {
        width: 100%;
        border-radius: 9px;
        background-color: $purple;
        color: $white;

        &:hover {
            background-color: $purple;
        }
    }

    .descs {
        max-height: 400px;
        overflow-y: auto;
        padding-right: 15px;
    }

    .job {
        display: flex;
        justify-content: space-between;

        .left,
        .right {
            display: flex;
            flex-direction: column;
            width: 50%;
        }

        .left {
            margin-right: 25px;
        }
    }

    .input {
        width: 100%;
        border: 1px solid $border;
        background-color: $body;
        height: 40px;
        border-radius: 3px;
        text-indent: 12px;
    }

    .dropdown {
        width: 100%;
        border-radius: 3px;
        font-size: 14px;

        fieldset {
            outline: none;
            border: none;
        }

        &.month {
            width: 140px;
            margin-right: 0.25rem;
        }

        &.year {
            width: 110px;
        }
    }

    &__add {
        &.no-visible {
            display: none;
        }

        form {
            .label {
                font-size: 14px;
                font-weight: 400;
                color: $black;
                display: block;
                margin-bottom: 5px;
            }

            .field {
                position: relative;
                @include tabletXS {
                    width: 100%;
                    margin-right: 20px;
                    .closer-dropwon,.dropdown {
                        width: 100% !important;
                    }
                }
            }
            @include tabletXS {
                .closer-dropwon,.dropdown {
                    width: 100% !important;
                }
            }

            .bottom {
                display: flex;
                justify-content: space-between;
                @include tabletXS {
                    flex-direction: column;
                }

                .left {
                    display: flex;
                    align-items: center;
                }

                .right {
                    display: flex;
                    align-items: center;
                }
            }

            .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
                font-size: 14px;
            }
        }
    }

    .add-experience {
        display: flex;
        align-items: center;
        margin: 25px 0;
        width: max-content;
        cursor: pointer;

        .action-add {
            width: 36px;
            height: 36px;
        }

        .add-experience-title {
            font-size: 14px;
            font-weight: 400;
            color: $black;
            display: block;
            margin-left: 10px;
        }
    }

}