@import '../../assets/variables/colors.scss';

.culture {
  display: flex;
  padding-bottom: 60px;
  margin-top: 20px;
  
  .block {
    background-color: $white;
    padding: 44px 35px;
    width: 50%;
  }
  &__more_info {
    align-self: flex-end;
  }
  
  &__left {
    border-radius: 6px;
    &_title_container {
      display: flex;
      justify-content: space-between;
    }
    
    &_title_container_popup {
      display: flex;
      justify-content: flex-end;
    }
    
    &-title {
      font-size: 24px;
      color: $black;
      font-weight: 600;
    }
    
    &-ranges {
      padding: 0 30px;
      
      &-range {
        margin-top: 30px;
      }
    }
  }
  
  &__right {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    
    &-chart {
      canvas {
        margin: auto;
        width: 616px;
        height: 490px;
      }
    }
    
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      
      &-comp {
        background-color: rgba(82, 37, 131, 0.12) !important;
        color: $purple !important;
      }
      
      &-save {
        background-color: $purple !important;
        color: $white !important;
        margin-left: 15px !important;
      }
      
      .btn {
        width: 258px !important;
        height: 47px !important;
        border-radius: 9px !important;
      }
    }
  }
}
.popup-add-new {
  max-width: 976px;
  padding: 40px;
  max-height: 1181px;
  margin: 20px 0;

  }

.backgrounds{
  background-color: #F5F5F5 !important;
}
