@import '../../assets/variables/colors.scss';
@import '../../assets/variables/colors.scss';

.images::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.images::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px $gray;
  border-radius: 10px;
}

.images::-webkit-scrollbar-thumb {
  background: $purple;
  border-radius: 10px;
}

.sharedContainer {
  max-width: 272px;
  .images {
    overflow-x: auto;
    margin-top: 20px;
    display: flex;
    gap: 20px;

    .image {
      position: relative;
      .trash-shared {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-13px, 13px);
        cursor: pointer;
      }
      .shared-images {
        height: 122px;
        border-radius: 13px;
        object-fit: cover;
      }

      &:nth-child(1) {
        .shared-images {
          width: 131px;
        }
      }

      &:nth-child(2) {
        .shared-images {
          width: 276px;
        }
      }

      &:nth-child(3) {
        .shared-images {
          width: 131px;
        }
      }

      &:nth-child(4) {
        .shared-images {
          width: 276px;
        }
      }

      &:nth-child(5) {
        .shared-images {
          width: 131px;
        }
      }
    }
  }

  .about-side {
    .field {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;

      .label {
        font-size: 14px;
        font-weight: 400;
        color: $black;
        display: block;
        margin-bottom: 5px;
      }

      .input {
        width: 100%;
        height: 40px;

        input {
          background-color: $body;
          outline: none;
          border-radius: 6px;
          border: 0;
          text-indent: 15px;
          width: 100%;
          height: 100%;
        }
      }
    }

    .drag-and-drop_image {
      margin-top: 0px !important;
      border-radius: 28px;
      border: 1px dashed rgba(82, 37, 131, 0.19);
      background: rgba(82, 37, 131, 0.07);
      height: 222px;
      margin-bottom: 25px;
      margin-top: 30px;
      padding: 15px;
      max-width: 272px;
      width: 100%;

      .browse {
        min-width: 236px;
        height: 47px;
        border-radius: 9px;
        background-color: $light-rose;
        color: $purple;
        font-weight: 500;
        font-size: 16px;
        margin-top: 23px;
      }

      .texts {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .upload {
          margin-bottom: 5px;
        }

        .drag-title {
          color: $black;
          font-weight: 600;
          font-size: 14px;
          display: block;
          margin: 5px 0;
        }

        .supported {
          color: $black;
          opacity: 0.5;
          font-size: 12px;
          display: block;
          margin-top: 5px;
        }

        .or {
          color: $black;
          font-weight: 600;
          font-size: 14px;
          display: block;
          margin-top: 15px;
        }
      }
    }
  }

  .save {
    width: 100%;
    height: 40px;
    background-color: $purple !important;
    color: $white !important;
    font-weight: 400;
    border-radius: 9px;

    &:hover {
      background-color: $purple !important;
      color: $white !important;
    }
  }
}

.file {
  visibility: hidden;
}
