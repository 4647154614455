@import '../../../../assets/variables/colors.scss';
@import '../../../../assets/variables/mixins.scss';

.edu {
  .nex-btn-s {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .skip {
      margin-right: 20px;
    }
  }

  .err-auto {
    border: 1px solid $warning;
  }

  .no-margin {
    margin: 0 !important;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    @include mobile {
      flex-direction: column;
    }

    &-left {
      display: flex;
      align-items: center;
      justify-content: 'space-between';

      &.left-edu {
        width: 100%;

        @include mobile {
          flex-direction: column;
        }

        .field {
          width: 100% !important;
        }

        .start-field {
          width: 142px;
        }
      }

      &.disable {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }

  .date {
    flex-direction: column;
    align-items: start;
  }

  .field {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.edu-fields {
      margin-right: 20px;
    }

    .box {
      margin-bottom: 10px;
    }
  }

  .label {
    color: $black;
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-bottom: 8px;
  }

  .year-drop {
    width: 127px;
  }

  .year-field {
    width: 127px;
  }

  .input,
  .dropdown {
    border-radius: 3px;
    border: 1px solid $border;
    background: $background;
    height: 40px;
    outline: none;
    text-indent: 12px;
    width: 100%;

    fieldset {
      outline: none;
      border: 0;
    }
  }

  .main-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .btn {
      width: 187px;
      height: 47px;
      border-radius: 9px;
      font-size: 16px;
      font-weight: 500;

      &.back {
        background-color: $storm-white;
        color: $purple;
      }

      &.next {
        background-color: $purple;
        color: $white;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .button {
      width: 187px;
      height: 47px;
      border-radius: 9px;

      &.back {
        background-color: $storm-white;
        color: $purple;
      }

      &.next {
        background-color: $purple;
        color: $white;

        &.one-btn {
          width: 100%;
        }
      }
    }
  }
}
