@import '../../../../../assets/variables/colors.scss';

.profileForm {
  margin-top: 30px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;


  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .editButton {
      border-radius: 50%;
      width: 36px;
      height: 36px;
      background-color: #522583;
      display: block;
      cursor: pointer;
      position: absolute;
      z-index: 10;
      right: 10px;
      bottom: 10px;
    }

    .company__top__left-image {
      width: 236px;
      height: 236px;
      border-radius: 25px;
      position: relative;
    }
  }

  .section-label {
    font-size: 18px;
    font-weight: 600;
    color: $black;
  }

  .label {
    color: $black;
    font-weight: 400;
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
  }

  &__email {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-email {
      font-weight: 400;
      font-size: 14px;
      color: $black;
      opacity: 0.8;
    }
  }

  &__eform {
    form {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mail {
        width: 100%;
        margin-right: 20px;


        &__input {
          background-color: $body;
          border: 1px solid $border;
          outline: none;
          width: 100%;
          height: 40px;
          border-radius: 3px;
          text-indent: 12px;
        }
      }

      .save {
        background-color: $purple;
        color: $white;
        width: 108px;
        height: 40px;
        border-radius: 9px;
      }
    }
  }

  &__pass {
    form {
      .passForm {
        margin-top: 20px;

        &__input {
          background-color: $body;
          border: 1px solid $border;
          outline: none;
          width: 100%;
          height: 40px;
          border-radius: 3px;
          text-indent: 12px;
        }
      }

      .repeats {
        display: flex;
        align-items: center;

        &__field {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .change_pass {
        border-radius: 9px;
        background-color: $purple;
        width: 100%;
        height: 40px;
        color: $white;
        font-weight: 500;
        font-size: 16px;
        margin-top: 30px;
      }
    }
  }
}
