@import '../../../../assets/variables/colors.scss';

.preference {
    .error {
        border: 1px solid $warning !important;
    }

    .ind {
        margin-bottom: 20px;
    }

    .industry-selected {
        height: 26px;
        background-color: $body;
        color: $gray;
        font-size: 12px;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
        padding: 0 5px;
        margin-top: 10px;

        .remove {
            cursor: pointer;
            margin-left: 8px;
        }
    }

    .top {
        display: flex;
        align-items: center;
    }

    .next {
        width: 100%;
        border-radius: 9px;
        background-color: $purple;
        color: $white;
        border: 1px solid $purple;

        &:hover {
            color: $purple;
        }
    }

    .label {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        display: block;
        margin-bottom: 8px;

        &.gender {
            font-size: 16px;
            font-weight: 600;
            display: block;
            margin-top: 25px;
        }
    }

    .fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 30px;
        padding-bottom: 20px;

        &.loc {
            margin-bottom: 20px;
        }

        &:first-child {
            max-width: 344px;
        }

        &.willing {
            width: 240px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .checkboxes {
        .bold-title {
            font-weight: 600;
            font-size: 16px;
            color: $black;
        }

        .contract {
            width: 100%;

            .MuiFormGroup-row {
                justify-content: space-between;
            }
        }

        .input {

            width: 100%;
        }
    }



    .input,
    .dropdown {
        border-radius: 3px;
        border: 1px solid $border;
        background: $background;
        height: 40px;
        outline: none;
        text-indent: 12px;
        margin-bottom: 20px;

        fieldset {
            outline: none;
            border: 0;
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        &.one {
            .button {
                width: 100%;

                .invalid {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }

        .button {
            width: 187px;
            height: 47px;
            border-radius: 9px;

            &.back {
                background-color: $storm-white;
                color: $purple;
            }

            &.oneBTN {
                margin-top: 20px;
            }

            &.next {
                background-color: $purple;
                color: $white;


                &.invalid {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }
}