@import '../../../assets/variables/colors.scss';

.multisel {
  .MuiChip-filled {
    display: none !important;
  }
}

.multi {
    position: relative;

    .arrow-down {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-15px, 13px);

    &.active {
      transform: translate(-15px, 13px) rotate(180deg);
    }
  }
  .error {
    border: 1px solid $warning;
  }
}
