@import '../../../assets/variables/colors.scss';
@import '../../../assets/variables/mixins.scss';

.tab {
  margin-top: 15px;
  display: flex;
  column-gap: 20px;

  &__videos {
    width: 620px;
    padding-right: 10px;
  }

  &__photos {
    background-color: $white;
    border-radius: 6px;
    width: 505px;
    padding: 20px;

    .image_container {
      border-radius: 14px;
    }

    &__photos {
      background-color: $white;
      border-radius: 6px;
      width: 48%;
      margin-right: 15px;
      padding: 20px;

      &:last-child {
        width: 60%;
        margin: 0;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-title {
          color: $black;
          font-size: 20px;
        }
      }

      &__content {
        margin-top: 15px;
        display: flex;
        align-items: center;

        &-photo {
          margin-right: 15px;
        }

        &-video {
          margin-right: 20px;
          margin-bottom: 20px;
          width: 262px;
          height: 152px;
        }
      }
      @include tablet {
        width: 100% !important;
        margin-bottom: 20px;
      }

      &:last-child {
        width: 60%;
        margin: 0;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-title {
          color: $black;
          font-size: 20px;
        }
      }

      &__content {
        margin-top: 15px;
        display: flex;
        align-items: center;

        &-photo {
          margin-right: 15px;
        }

        &-video {
          margin-right: 20px;
          margin-bottom: 20px;
          width: 266px;
          height: 156px;
        }
      }
    }
  }
}
