@import '../../../assets/variables/colors.scss';

.competenciesb {
  padding: 25px;

  &__top {
    display: flex;
    justify-content: space-between;

    &__left {
      display: flex;
      flex-direction: column;

      &-title {
        font-size: 20px;
        font-weight: 600;
        display: block;
        margin-bottom: 11px;
      }

      &-percent {
        color: $light-blue;
        font-weight: 600;
        font-size: 20px;
      }
    }

    &__right {
        text-align: center;
      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &-dot {
          width: 18px;
          height: 18px;
          background-color: $light-blue;
          border-radius: 50%;

          &.purple {
            background-color: $purple;
          }
        }

        &-title {
          font-size: 18px;
          color: $black;
          font-weight: 400;
          display: block;
          margin-left: 7px;
        }
      }
    }
  }
  .career_page {
    padding: 14px 38px;
    background-color: #e1dbe7;
    color: #522583;
    border-radius: 10px;
  }
}
