.match {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  .content-container {
    padding: 20px 50px;
    display: flex;
    gap: 25px;
  }

  .footers {
    margin-top: auto;
  }
}
