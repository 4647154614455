@import '../../../../assets/variables/colors.scss';

.upgrade {
    .texts {
        color: $black;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
    }

    &__top {
        margin-bottom: 10px;
        margin-top: 30px;
    }

    &__list {
        padding: 0 0 0 22px;
        margin: 0;
        margin-top: 15px;
        list-style-type: disc;

        &-item {
            margin-bottom: 15px;
        }
    }
}