@import '../../../../assets/variables/colors.scss';

.company-info {
  color: $black;
  text-align: center;
  margin: 20px 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .label {
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
    color: $black;
  }

  .save {
    background-color: $purple;
    width: 100%;
    color: $white;
    border-radius: 9px;

    &.invalid {
      opacity: 0.8;
      pointer-events: none;
    }

    &:hover {
      background-color: $purple;
      color: $white;
    }
  }

  .field {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;

    .examp {
      font-size: 12px;
      opacity: 0.8;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(10px, 11px);
      color: $black;
    }

    .input {
      width: 100%;
      height: 40px;
      border-radius: 3px;
      border: 1px solid $border;
      background-color: $body;
      text-indent: 15px;
      outline: none;
    }
    .genders {
      color: $black;
    }
  }
  .av__content__buttons {
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
}
