@import '../../../../assets/variables/colors.scss';
@import '../../../../assets/variables/mixins.scss';

/* width */
form::-webkit-scrollbar {
  width: 10px;
}

/* Track */
form::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px $gray;
  border-radius: 10px;
}

/* Handle */
form::-webkit-scrollbar-thumb {
  background: $purple;
  border-radius: 10px;
}

.day {
  width: 143px;
  height: 40px;
}

.month {
  width: 180px;
  height: 40px;
}

.year {
  width: 190px;
  height: 40px;
}

.dropdown-cl {
  margin: 0;
}

.dropdown {
  background-color: $body !important;
  border: 1px solid $border !important;
  height: 40px;

  fieldset {
    border: none;
  }
}

.input {
  background-color: $body !important;
  border: 1px solid $border !important;
}

.about__popup {
  &-title {
    display: block;
    text-align: center;
    font-size: 24px;
    color: $black;
    font-weight: 600;
  }

  .save-btn-a {
    width: 100%;
    background-color: $purple;
    color: $white;
    font-weight: 400;
    border: 1px solid $purple;

    &:hover {
      background-color: $white;
      color: $purple;
    }
  }

  form {
    width: 100%;
    margin-top: 25px;
    height: auto;
    overflow-y: auto;
    padding-right: 10px;
    @include mobile {
      height: 65vh;
    }
    .about-input {
      width: 100%;
      height: 40px;
      background-color: $border;
      border: 1px solid $light-rose;
      text-indent: 15px;
      outline: none;

      &.pitch {
        height: 50px;
      }
    }

    .label {
      margin-bottom: 5px;
      color: $black;
      font-size: 14px;
      display: block;
    }

    .names {
      display: flex;
      align-items: center;
      @include mobile {
        display: flex;
        flex-direction: column;
      }
      &__first {
        width: 50%;
        margin-right: 15px;
        @include mobile {
          width: 100%;
          margin: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .locations {
      margin-bottom: 20px;
    }

    .contactForm {
      display: flex;
      @include mobile {
        display: flex;
        flex-direction: column;
      }

      .phone {
        .phone_dropdown {
          height: 40px;
          width: 250px !important;
          background-color: $border;
          border: 1px solid $light-rose;
          @include mobile {
            width: 100% !important;
            margin-bottom: 20px;
          }
        }

        &.contact__val {
          width: 100%;
          margin-left: 15px;
          @include mobile {
            margin: 0;
          }
        }
      }
    }

    .birthday {
      .fields {
        display: flex;
        justify-content: space-between;
        @include mobile {
          flex-direction: column;
          .dropdown-cl,
          .dropdown {
            width: 100% !important;
            margin-bottom: 20px;
          }
        }

        .birthday_dropdown {
          height: 40px;
          background-color: $border;
          border: 1px solid $light-rose;
        }
      }
    }

    .gender {
      margin: 25px 0;
    }

    .labels {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 75px;
      margin-bottom: 10px;
    }

    .language {
      .contents {
        display: flex;

        &.flexable {
          flex-direction: column;

          .langs {
            display: flex;
            margin-bottom: 5px;
          }
        }

        .left {
          width: 100%;

          .language__dropdown {
            width: 100%;
          }
        }

        .right {
          margin-left: 15px;
          display: flex;
          align-items: center;
          padding-right: 10px;

          .trash {
            cursor: pointer;
          }

          .level {
            margin-top: 0;

            .dots {
              justify-content: center;
            }
          }
        }
      }
    }

    .dropdowns {
      display: flex;
      align-items: center;
      margin-top: 25px;

      .select {
        width: 30%;

        .network {
          width: 100%;
        }
      }

      .url {
        margin-left: 15px;

        width: 55%;

        .field {
          width: 100%;
          margin-bottom: 0;
        }
      }

      .right {
        display: flex;
        margin-left: 20px;
        column-gap: 10px;
        margin-top: 20px;

        img {
          cursor: pointer;
        }
      }
    }

    .action_button {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 25px;
      margin-bottom: 25px;
      max-width: max-content;

      .label {
        margin-bottom: 0;
        margin-left: 15px;
      }
    }

    .rss {
      margin-top: 15px;

      .field {
        margin-bottom: 0;
      }
    }

    .interests {
      display: flex;
      align-items: center;

      .left {
        width: 50%;
      }

      .right {
        width: 50%;
        margin-left: 15px;
      }
    }
  }

  .save-btn {
    border-radius: 9px;
    color: $white;
    font-weight: 500;
    font-size: 16px;
    padding: 14px 22px;
    margin-top: 30px;
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    .checkbox-label {
      font-size: 14px;
      font-weight: 400;
      color: $black;
    }
  }

  .autocomplete {
    position: relative;
    margin-top: 25px;
    margin-bottom: 25px;

    .location {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      transform: translate(-13px, -12px);
    }
  }

  .language {
    margin-top: 25px;
  }
}
