.about__right__bottom__right {
    .dots {
        .dot {
            width: 12px;
            height: 12px;
        }
    }

    .scrollable {
        overflow-x: hidden;
    }
}