@import '../../../../../assets/variables/colors.scss';

.social {
    display: flex;
    flex-direction: column;
    margin-top: 38px;

    .linkedin {
        background: $sky-blue;
        color: $white;
        font-weight: 500;
        font-size: 16px;

        &:hover {
            background-color: $sky-blue;
        }

        .btn-icon {
            margin-right: 10px;
        }
    }

    .facebook {
        background-color: $blue;
        color: $white;
        font-weight: 500;
        font-size: 16px;
        margin-top: 20px;

        .btn-icon {
            margin-right: 10px;

        }
        &:hover {
            background-color: $blue;
        }
    }
}