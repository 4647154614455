@import '../../assets/variables/colors.scss';

.jCard {
    width: 100%;
    height: 109px;
    box-shadow: 0px 1px 103px 0px $shadow;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 13px;
    padding: 32px 20px;

    &__left {
        display: flex;
        align-items: center;
        max-width: 500px;
        width: 100%;

        &-image {
            width: 75px;
            height: 75px;
            border-radius: 50%;
        }

        &__info {
            display: flex;
            flex-direction: column;
            margin-left: 17px;

            &-title {
                color: $black;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 8px;
                display: flex;
                align-items: center;

                &-type {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    margin-left: 5px;
                    border-radius: 5px;
                    background: rgba(0, 158, 226, 0.10);
                    min-width: 65px;
                    height: 22px;
                    color: $role-color;
                    font-size: 12px;
                }
            }

            &-sub {
                color: $black;
                font-size: 14px;
                font-weight: 400;
                opacity: 0.7;
            }
        }
    }

    &__middle {
        display: flex;
        align-items: flex-start;

        &__matches {
            display: flex;
            flex-direction: column;

            &-title {
                opacity: 0.7;
                font-weight: 500;
                font-size: 14px;
                color: $sky-blue;
                display: block;
                margin-bottom: 10px;
            }
        }

        &__details {
            display: flex;
            margin-left: 50px;

            &__item {
                display: flex;
                flex-direction: column;
                margin-right: 50px;

                &:last-child {
                    margin-right: 0;
                }

                &-title {
                    font-weight: 500;
                    font-size: 14px;
                    color: $black;
                    opacity: 0.7;
                    display: block;
                    margin-bottom: 10px;
                }

                &-desc {
                    font-size: 14px;
                    color: $black;
                    opacity: 0.7;
                }
            }
        }

    }

    &__right {
        display: flex;
        align-items: center;
        max-width: 200px;
        width: 100%;
        justify-content: flex-end;

        &__actions {
            display: flex;

            &-action {
                display: block;
                margin-right: 20px;
                cursor: pointer;

                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
        }
    }
}