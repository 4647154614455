@import '../../../../assets/variables/colors.scss';

.sharejob {
    margin-top: 25px;

    &__content {
        position: relative;

        &-label {
            color: $black;
            font-size: 14px;
            font-weight: 400;
            display: block;
            margin-bottom: 5px;
        }

        &-link {
            width: 100%;
            height: 40px;
            border: none;
            background-color: $body;
            border: 1px solid $border;
            border-radius: 3px;
            text-indent: 15px;
            outline: none;
            cursor: pointer;
        }

        &-share {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(-19px, -11px);
        }
    }

    &__share {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;

        &-btn {
            position: relative;
            width: 100%;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            .img-btn {
                position: absolute;
                left: 0;
                top: 0;
                transform: translate(40px, 14px);
            }

            .button {
                width: 100%;
                height: 47px;
                color: $white !important;
                text-align: center;
                border-radius: 9px;

                &.linkedin {
                    background-color: $blue !important;
                }

                &.facebook {
                    background-color: $sky-blue !important;
                }
            }

        }
    }
}