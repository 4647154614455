@import '../../assets/variables/colors.scss';

.invisible {
  visibility: hidden;
  display: none;
}

.preview {
  .footer,
  .navbar,
  .btns,
  .btn,
  .disable-block {
    pointer-events: none;
    opacity: 0.8;
  }
}

.view_as {
  background-color: #fcfcfd;
  width: 100% !important;
  max-width: 1059px !important;
  padding-top: 40px;

  .popup__title {
    font-size: 20px;
    line-height: 25px;
    width: 908px;
    margin: auto;
  }
}

.dashboard {
  width: 100%;
  padding: 0 30px 30px 30px;
  max-width: 1520px;
  margin: 0 auto;

  &__main {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    border-radius: 6px;
    padding-top: 15px;
    background-color: $white;
    padding: 12px;

    &__content {
      padding: 0px;
    }

    &__image {
      position: relative;
      margin-bottom: 15px;
      width: 278px;
      height: 239px;
      margin: auto;
      text-align: center;

      &-img {
        margin: auto;
        border-radius: 24px;

        .letter {
          font-size: 100px;
        }
      }

      &-edit {
        display: block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $light-purple;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-45px, 12px);
        cursor: pointer;

        &-pen {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &__swiper {
      width: 100%;
      background-color: $light-purple;
      border-radius: 32px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;

      &-btn {
        text-align: center;
        display: block;
        width: 48%;
        border-radius: 45px;
        transition: all 0.5s;
        cursor: pointer;
        color: $soft-purple;
        padding: 5px 6px;

        &:hover {
          color: $white;
          background-color: $purple;
        }

        &.active {
          color: $white;
          background-color: $purple;
        }
      }
    }

    &__link {
      position: relative;
      border: 1px solid $border;
      height: 34px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      background-color: $light-purple;
      margin-top: 27px;

      &__tool {
        transform: translateY(-38px);
      }

      &-input {
        position: absolute;
        width: 87%;
        background-color: transparent;
        color: $black;
        cursor: copy;
        outline: none;
        padding: 14px;
        border: 0;
      }

      &-refresh {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-13px, 11px);
        cursor: pointer;
      }
    }

    &-back {
      color: $purple;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-top: 20px;

      .arrow-left {
        margin-right: 20px;
      }
    }

    &__contact {
      margin-top: 15px;
      font-size: 12px;

      &__user {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &__icon {
          margin-right: 10px;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background-color: $light-purple;
          position: relative;

          &-img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &__social {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      row-gap: 5px;

      a {
        display: block;
        margin-right: 15px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      margin-top: 47px;

      .otherColorButton {
        button {
          color: $purple;
        }
      }

      button {
        color: $white;
        font-weight: 500;
        border-radius: 9px;
        height: 47px;
        margin-bottom: 15px;
        width: 274px;

        .btn-icon {
          margin-right: 10px;
        }
      }
      /////////
      ///
      ///
      &-lightGreen {
        background-color: $passed !important;

        &:hover {
          background-color: $passed !important;
        }
      }

      &-purple {
        background-color: $purple !important;

        &:hover {
          background-color: $purple !important;
        }
      }

      &-sky {
        background-color: $sky-blue !important;

        &:hover {
          background-color: $sky-blue !important;
        }
      }

      &-lightPurple {
        background-color: rgba(82, 37, 131, 0.12) !important;

        &:hover {
          background-color: rgba(82, 37, 131, 0.12) !important;
        }
      }

      &-green {
        background-color: $green !important;

        &:hover {
          background-color: $green !important;
        }
      }
    }
  }

  .dashboard__right {
    width: 100%;
  }

  &__right {
    margin-left: 15px;
    width: 80%;
    border-radius: 6px;

    .dashboard__right__top__left-title {
      cursor: pointer;
      width: max-content;
      color: #2a2a2a;
    }

    .inp {
      display: flex;
      flex-direction: column;
      position: relative;

      .error {
        position: absolute;
        bottom: 0;
        left: 0;
        color: $warning;
        font-size: 12px;
        transform: translate(10px, 16px);
      }
    }

    &__top {
      border-radius: 6px;
      padding: 20px;
      background-color: $white;
      display: flex;
      justify-content: space-between;

      &__left {
        width: 39%;

        &-title {
          display: flex;
          font-size: 20px;
          font-weight: 600;
          pointer-events: none;
        }

        &-desc {
          color: $black;
          opacity: 0.5;
          margin-top: 10px;
          line-height: 23px;
        }

        &__email {
          display: flex;
          align-items: center;
          margin-top: 10px;

          &-email {
            height: 34px;
            text-indent: 10px;
            border-radius: 3px 0px 0px 3px;
            border: 1px solid $border;
            background: $body;
            outline: none;
            width: 350px;
          }

          &-submit {
            width: 115px;
            height: 34px;
            border-radius: 0 9px 9px 0 !important;
            background-color: $purple !important;
            border: 1px solid $purple !important;
            color: $white !important;
            font-size: 14px;
            font-weight: 500;

            &.deactive {
              pointer-events: none;
              opacity: 0.8;
            }

            &:hover {
              background-color: $white !important;
              color: $purple !important;
            }
          }
        }
      }

      &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__button {
          border-radius: 9px !important;
          color: $purple !important;
          background-color: $light-rose !important;

          &:hover {
            border: 1px solid $light-rose !important;
            background-color: transparent !important;
          }

          &.app {
            width: 236px;
            height: 47px;
            font-weight: 500;
          }

          &.job {
            margin-top: 20px;
            width: 150px;
            height: 47px;
            font-weight: 500;

            .btn-icon {
              margin-right: 20px;
            }
          }
        }
      }
    }

    &__bottom {
      &__tabs {
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $white;
          margin-top: 0px !important;
          padding: 10px 23px;
          margin-bottom: 15px;

          &__item {
            &-title {
              cursor: pointer;
              font-size: 16px;
              color: $black;
              position: relative;

              &.active {
                .line {
                  width: 100%;
                }
              }

              &:hover {
                .line {
                  width: 100%;
                }
              }

              .line {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $purple;
                height: 4px;
                width: 100%;
                border-radius: 5px;
                transform: translateY(13px);
                transition: all 0.5s;
                width: 0;
              }
            }
          }
        }
      }
    }
  }
}
