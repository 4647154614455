@import '../../assets/variables/colors.scss';
@import '../../assets/variables/mixins.scss';

body {
  height: 100vh;
}

.profile {
  margin-right: 10px;
}

.upgrade-plan-nav {
  max-width: none !important;

  &.upgrade-popup-nav {
    max-width: 1079px !important;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 95px;
  background-color: $white;
  margin-bottom: 25px;
  &-logo {
    img {
      width: 250px;
      height: 50px;
      @include tablet {
        width: 150px;
      }
    }

  }
  @include tablet {
    justify-content: space-between;
  }
  .default-img {
    margin-right: 10px;
  }

  &__left {
    display: flex;
    align-items: center;
    z-index: 9;

    &-btn {
      min-width: 108px !important;
      height: 47px !important;
      background-color: $purple !important;
      color: $white !important;
      border-color: $purple !important;
      border-radius: 9px !important;
      @include tablet {
        display: none !important;
      }
      &:hover {
        color: $purple;
      }
    }

    &__dropdown {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 202px;
      margin-left: 53px;
      @include tablet {
        margin-left: 0;
      }
      &-btn {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
      }

      &-name {
        margin-right: 18px;
        white-space: nowrap;
      }

      &__content {
        padding: 20px 27px;
        min-width: 171px;
        min-height: 164px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background: $white;
        box-shadow: 0px 1px 12px 0px rgba(141, 156, 174, 0.25);
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(35px, 180px);
        @include tablet {
          transform: translate(-25px, 201px);
        }

        &.notification {
          width: 395px;
          transform: translate(28px, 100%);

          .card {
            margin-bottom: 15px;
          }
        }

        &__cards {
          &__ul {
            padding: 0;
            margin: 0;

            &__li {
              display: flex;
              align-items: center;
              margin-bottom: 18px;
              cursor: pointer;
              &.upgrade-li {
                display: none;
                @include tablet {
                  display: flex;
                }
              }

              &.lang-li {
                flex-direction: row-reverse !important;
                justify-content: space-between;
              }

              &:last-child {
                margin-bottom: 0;
              }

              &-title {
                color: $black;
                font-size: 16px;
                font-weight: 400;
                display: block;
                margin-left: 8px;
              }

              &__lang {
                display: flex;
                align-items: center;
                margin-right: 22px;

                &:first-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }
      &-name { color:#2a2a2a }

      &-arrow {
        transform: rotate(180deg);

        &.active {
          transform: rotate(0deg);
        }
      }
    }
  }
}
