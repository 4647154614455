@import '../../../assets/variables/colors.scss';

.candidateOverview {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  padding: 20px 50px 15px 50px;
  &__left {
    width: 300px;

    &__incoming {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 23px;
      background-color: $white;
      border-radius: 6px;

      &-title {
        text-align: center;
        color: $black;
        font-size: 23px;
        font-weight: 600;
        display: block;
        margin-bottom: 13px;
      }

      &-drop {
        margin-top: 17px;
        width: 100%;
      }

      &-desc {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: $black;
        line-height: 20px;

        &-open {
          color: $purple;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    &__req {
      display: flex;
      justify-content: space-between;
      width: 300px;
      background-color: $white;
      padding: 17px 30px;
      border-radius: 6px;
      margin-top: 10px;
      cursor: pointer;

      &__left {
        display: flex;
        align-items: center;

        &-title {
          font-weight: 500;
          font-size: 16px;
          color: $black;
        }

        &-circle {
          background-color: $warning;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 13px;
          border-radius: 50%;
          margin-left: 5px;
        }
      }

      &-arrow {
        transform: rotate(-90deg);
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__top {
      background-color: $white;
      margin-bottom: 10px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      margin-bottom: 20px;
      &-title {
        color: $black;
        font-size: 24px;
        font-weight: 600;
        display: block;
        margin-bottom: 15px;
      }

      &__counts {
        display: flex;
        align-items: center;
        
        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border-radius: 70px;
          color: $white;
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          border: none;
          min-width: 120px;
          height: 27px;
        }

        &-warn {
          background-color: $warning;
        }

        &-pass {
          background-color: $passed;
          margin-left: 10px;
        }
      }
    }
    &__bottom {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .select_jobs {
    margin: 20px 0;
    max-width: 297px;
    width: 100%;
    height: 51px;
    border-radius: 10px !important;
    padding: 0 15px !important;
    .MuiInput-input {
      padding: 0 !important;
    }
    &::before {
      content: none !important;
      border: none !important;
    }
    &::after {
      content: none !important;
      border: none !important;
    }
  }
}
.popUp {
  max-width: max-content;
}

.MuiInputBase-input:focus {
  background-color: transparent;
}
