@import '../../../../assets/variables/colors.scss';

.fieldset {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .field {
        width: 100%;
        margin-left: 20px;
        margin-right: 0 !important;
        margin-bottom: 0;
    }

    .input {
        width: 100%;
        height: 40px;
        text-indent: 20px;
        padding-right: 20px;
        outline: none;
    }

    .trash {
        cursor: pointer;
        padding: 5px;
    }

}

.formData {
    overflow-y: auto;
    max-height: 400px;
    margin-top: 20px;
    padding-right: 20px;
}

.save-btn {
    width: 100%;
    background-color: $purple !important;
    border-radius: 9px !important;
    height: 47px;
    color: $white !important;
    margin-top: 40px;

    &:hover {
        color: $white;
        background-color: $purple;
    }
}

.error-side {
    border: 1px solid $warning;
}