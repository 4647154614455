@import '../../assets/variables/colors.scss';

.careerContainer {
  color: #23242a;
  background-color: $white;
  border-radius: 6px;
  padding: 35px;
  margin: 30px 50px 50px 50px;

  .input-formik {
    resize: none;
    width: 100%;
    height: 40px;
    background-color: $body;
    border: 1px solid $border;
    text-indent: 12px;
    margin-top: 10px;
    border-radius: 3px;
    outline: none;

    &.area {
      padding: 9px 0px;
      height: 140px;
      resize: none;
    }

    &.error {
      border: 1px solid $warning;
    }
  }

  .aboutContainer {
    .peopleSay {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;
      margin-top: 50px;
  

      h1 {
        font-size: 40px;
        font-weight: 700;
      }
    }
    .peopleContainer {
      position: relative;

      .peopleContent {
        padding: 0 35px;
        position: relative;
        top: -100px;
        gap: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }

      .purpleContainer {
        height: 175px;
        width: 100%;
        background-color: $purple;
        margin-top: 15px;
      }
    }

    .organizationalContainer {
      min-height: 500px;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 100px;
      margin-bottom: 70px;
      &_first {
        display: flex;
        justify-content: space-between;
      }

      &_second {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        padding: 1rem 0;
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $purple !important;
          border-radius: 10px;
        }
      }
      .align {
        flex: 0 0 auto;
        margin: 0 10px;
        gap: 10px;
        border-radius: 38.51px;
        &:nth-of-type(6n + 1) {
          align-self: flex-end;
        }

        &:nth-of-type(6n + 2) {
          align-self: flex-start;
          margin-left: auto;
        }

        &:nth-of-type(6n + 3) {
          align-self: flex-start;
          margin-right: auto;
        }

        &:nth-of-type(6n + 4) {
          align-self: flex-start;
        }

        &:nth-of-type(6n + 5) {
          align-self: flex-end;
          margin-right: auto;
        }

        &:nth-of-type(6n) {
          align-self: flex-start;
        }
      }
      h1 {
        font-size: 40px;
        font-weight: 700;
        max-width: 400px;
      }
    }

    .benefitsDescription {
      display: flex;
      justify-content: flex-end;
      align-items: end;
      .description {
        line-height: 25px;
        max-width: 549px;
        font-size: 20px;
      }
    }

    .contactContainer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 100px;
      position: relative;
      .bgImageWrapper {
        margin-bottom: 300px;
        position: relative;
        .bgPhoto {
          width: 100%;
          height: auto;
          max-height: 430px;
          max-width: 1269px;
        }
      }
      .formContainer {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;
        padding: 0 80px;
        top: 18%;
      }

      .infoSection {
        max-width: 435px;
        padding: 40px;
        max-height: 325px;
        width: 100%;
        background-color: $white;
        border-radius: 40px;
        box-shadow: 0px 1.72px 177.39px 0px rgba(141, 156, 174, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
          margin: 10px 0;
        }
        .email_text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          text-align: center;
        }

        .socialMediaContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 20px;
          margin-top: 40px;
        }
      }

      .contactForm {
        max-width: 490px;
        padding: 47px;
        width: 100%;
        background-color: $white;
        border-radius: 40px;
        box-shadow: 0px 1.72px 177.39px 0px rgba(141, 156, 174, 0.25);

        h1 {
          font-size: 34px;
          font-weight: 600;
          margin-bottom: 35px;
        }

        .bigInput {
          height: 190px;
        }

        .send {
          border-radius: 9px;
          background-color: $purple;
          height: 47px;
          width: 100%;
          color: $white;
          transition: all 0.5s;

          &:hover {
            background-color: $white;
            border: 1px solid $purple;
            color: $purple;
          }
        }
      }
    }
    .benefit_section_title {
      color: #23242a;
      font-size: 23px;
      margin-bottom: 42px;
    }

    .benefitsCartContainer {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 70px;
      row-gap: 50px;
      padding-right: 50px;
      div{
        word-break: break-all;
      }

      .benefitsCart {
        display: flex;
        align-items: flex-start;
        column-gap: 42px;
        max-width: 550px;

        .benefitsTitle {
          font-size: 30px;
          margin-bottom: 20px;
          font-weight: 500;
          word-break: break-all;
        }

        .benefitsDescription {
          font-size: 20px;
        }
      }
    }

    .benefitsContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 100px;

      h1 {
        font-size: 64px;
        font-weight: 700;
        max-width: 840px;
      }
    }

    .cartContainerFlex {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      row-gap: 30px;
      column-gap: 24px;
    }

    .headerContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .profileImageContainer {
        display: flex;
        align-items: center;
        column-gap: 10px;

        .image {
          border-radius: 50%;
        }
      }
    }

    .cultureProfileContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .cultureProfileChart {
        background-color: #f9f9fd;
        padding: 30px;
        margin-top: 32px;
        margin-bottom: 128px;
        border: 1px solid #ebdff8;
        border-radius: 10px;
        min-width: 562px;
        min-height: 562px;
      }
      span {
        font-size: 20px;
        align-self: flex-start;
      }

      .cultureProfile {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;

        h1 {
          font-size: 40px;
          font-weight: 700;
        }
      }
      span {
        margin-top: 10px;
      }
    }
    .next {
      height: 50px;
      border-radius: 10px;
      background-color: $purple;
      color: $white;
      width: 140px;
      margin-top: 15px;

      &:hover {
        background-color: $white;
        border: 1px solid $purple;
        color: $purple;
      }
    }
    .cardSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;
      .all {
        max-width: 183px;
        width: 100%;
      }
    }

    .pencil_icon {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
    }

    .pencil_circle {
      background-color: $purple;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      position: absolute;
      opacity: 7%;
    }

    .headerTitle {
      max-width: 870px;
      width: 100%;
      margin: 30px 0px;
      h1 {
        font-size: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.2;
      }
    }

    .bannerImage {
      width: 100%;
      height: 528px;
      object-fit: cover;
    }

    .aboutUsContainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      column-gap: 70px;
      margin-top: 60px;
      span {
        max-width: 781px;
        width: 100%;
        margin-top: 20px;
        line-height: 25px;
      }
      p {
        font-weight: 700;
      }
    }

    .jobOpeningContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;
      margin-top: 100px;

      h1 {
        font-size: 40px;
        font-weight: 600;
      }
    }
  }

  .filterContainer {
    padding: 30px 35px;
    margin-top: 40px;
    border-radius: 5px;
    margin-bottom: 35px;

    .fields_flex {
      align-items: center;
      justify-content: space-evenly;

      .clearContainer {
        height: 60px;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: $purple;
        span {
          cursor: pointer;
        }
      }
    }
  }

  .fieldsSalary {
    min-width: 270px;
    .salary_label {
      display: flex;
      column-gap: 50px;
    }
  }

  .inputContainer {
    min-width: 190px;
    display: flex;
    flex-direction: column;
  }
  .videoWrapper {
    position: relative;
    .react-player {
      border-radius: 38.51px;
      div {
        border-radius: 38.51px;
      }
    }
    .start {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .edit-info {
    position: absolute;
    top: 25px;
    right: 25px;
  }
}
.loadingContainer {
  display: flex;
  justify-content: center;
}

.label {
margin-bottom: 8px;
}

.career_back_button {
cursor: pointer;
height: 47px;
display: flex;
align-items: center;
margin-bottom: 20px;
}