@import '../../../../assets/variables/colors.scss';

.who {
    form {
        .top {
            display: flex;
            margin-bottom: 25px;
        }
    }

    .bottom {
        display: flex;
        align-items: center;
    }

    .next {
        width: 100%;
        border-radius: 9px;
        background-color: $purple;
        color: $white;
        border: 1px solid $purple;

        &.invalid {
            opacity: 0.8;
            pointer-events: none;
        }

        &:hover {
            color: $purple;
        }
    }

    .label {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        display: block;
        margin-bottom: 8px;

        &.gender {
            font-size: 16px;
            font-weight: 600;
            display: block;
            margin-top: 25px;
        }
    }

    .radio-gender {
        margin-bottom: 15px;
    }

    .fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }

        .downs {
            display: flex;
            align-items: center;

            .dropdown {
                width: 100%;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .input {

        width: 100%;
        color:#2a2a2a
    }

    .input,
    .dropdown {
        border-radius: 3px;
        border: 1px solid $border;
        background: $background;
        height: 40px;
        outline: none;
        text-indent: 12px;

        fieldset {
            outline: none;
            border: 0;

        }
    }

}