@import '../../../assets/variables/colors.scss';
@import '../../../assets/variables/mixins.scss';

.skill-block-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;

  .blocks {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.skill-block {
  .block__content {
    min-height: 100px;
  }

  &.soft-skills {
    margin-top: 20px;
  }
}

.skills__block {
  padding-bottom: 15px;
  min-height: 232px;
  width: 503px;

  &.langs {
    width: 625px;

    @include tabletXS {
      display: flex;
      flex-direction: column;
      margin: 0;
      margin-top: 20px;
    }
  }
}

.skill-desc-block {
  width: 50px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.skill-title-block {
  width: 150px;
  flex-direction: revert !important;
}

.skill-desc-block {
  width: 100px;
}

.tab__content-skill {
  border-radius: 40px;
  background-color: $body;
  padding: 10px;
  margin-top: 15px;
  flex-direction: column;
  font-size: 14px;
  margin-right: 18px;
  margin-bottom: 18px;
  color: $dark-purple;
  font-weight: 400;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-size: 20px;
      color: $black;
      font-weight: 600;
    }

    &__actions {
      display: flex;
      align-items: center;

      &-btn {
        margin-left: 10px;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    padding-bottom: 15px;

    &-skill {
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 15px;
      border-radius: 40px;
      background-color: $body;
      color: $purple;
    }
  }
}

.skill_content {
  .skill-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;

    &:first-child {
      margin-top: 0;
    }
  }

  .skillLevel {
    margin-top: 0 !important;
  }

  .skill-title {
    font-weight: 500;
    font-size: 14px;
    color: $black;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-transform: capitalize;

    .lang-name {
      font-size: 14px;
      font-weight: 500;
    }

    .icon-flag {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }
}

.lang-and-skill {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;

  @include tabletXS {
    display: flex;
    flex-direction: column;
  }
}
